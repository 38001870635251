export interface ActivityVariables {
  [key: string]: string | number;
}

export interface ActivityRulesMap {
  [key: string]: {
    [subKey: string]: string;
  };
}

export interface ActivityRulesMap_Recharge {
  [language: string]: {
    [category: string]: {
      [type: string]: string;
    };
  };
}

export interface ActivityRulesMap_Rebate {
  [language: string]: {
    [type: string]: string;
  };
}


export type Language = "zh-CN" | "en-US" | "pt-BR" | "id-ID" | "hi-IN" | "en-PH";
type RechargeType = "FIRST" | "SINGLE" | "SUM";
type ResetType = "NONE" | "DAILY" | "WEEKLY" | "WEEKLY_DAY" | "MONTHLY_DAY" | "PERIODIC";
type AgencyTypes = "ALL" | "NEW_REGISTER";
type RebateType = 'RECHARGE' | 'NORECHARGE';
type HighestType = 'OTHER' | 'REBATE';
type GameTypes = 'ELECTRONIC' | 'CHESS' | 'FISHING' | 'VIDEO' | 'LOTTERY' | 'ELECTRONIC';
type AcviticyType = "RedPacket" | "Agency" | "AssistanceCash" | "SignIn" | "Recharge" | "Rebate" | "LuckyWheel" | "Assistance" | "Custom" | "RechargeReward" | "MysteryReward"
type AssistanceRewardType = "FIXED" | "RANGE";
type IncludeOrExclude = "EXCLUDE_GIFT" | "NORMAL";
type AwardType = "ACTIVITY" | "BALANCE";
type AwardTimeType = "IMMEDIATELY" | "DELAY";
type ExpiredAwardType = "ABANDONED" | "AUTO";
type TJoinType = "ALL" | "NEW_REGISTER";


// 公用第四条
const comTemplates_four: ActivityRulesMap = {
  "zh-CN": {
    ON: `本活动所赠送的奖金（不含本金）需{multiplier}倍有效投注才能提现，(投注仅限{limitData});`,
    OFF: `本活动所赠送的奖金（不含本金）需{multiplier}倍有效投注才能提现，(投注不限游戏平台);`
  },
  "en-US": {
    ON: `The bonus (excluding principal) of this event requires {multiplier} times of valid bets for withdrawal, (betting is limited to {limitData});`,
    OFF: `The bonus (excluding principal) of this event requires {multiplier} times of valid bets for withdrawal, (betting is not limited to game platform);`
  },
  "pt-BR": {
    ON: `O bônus (excluindo o principal) concedido por esta atividade requer {multiplier} apostas mais eficazes para serem retiradas, (A aposta está limitada a {limitData});`,
    OFF: `O bônus (excluindo o principal) concedido por esta atividade requer {multiplier} apostas mais eficazes para serem retiradas, (A aposta não está limitada a qualquer plataforma ou jogo);`
  },
  "id-ID": {
    ON: `Bonus yang diberikan dalam kegiatan ini (tidak termasuk modal) harus dipertaruhkan {multiplier} kali lipat untuk dapat ditarik，(Hanya Taruhan{limitData});`,
    OFF: `Bonus yang diberikan dalam kegiatan ini (tidak termasuk modal) harus dipertaruhkan {multiplier} kali lipat untuk dapat ditarik，(Taruhan tidak terbatas pada platform game);`
  },
  "hi-IN": {
    ON: `इस प्रमोशन द्वारा दी गई बोनस राशि (मूल राशि को छोड़कर) को निकालने के लिए {multiplier} गुना प्रभावी दांव लगाने की आवश्यकता है, (दांव केवल {limitData} तक सीमित है);`,
    OFF: `इस प्रमोशन द्वारा दी गई बोनस राशि (मूल राशि को छोड़कर) को निकालने के लिए {multiplier} गुना प्रभावी दांव लगाने की आवश्यकता है, (दांव किसी भी गेमिंग प्लेटफ़ॉर्म पर सीमित नहीं है);`
  },
};


//充值第四条
const comTemplates_four_Gift: ActivityRulesMap_Recharge = {
  "zh-CN": {
    ON: {
      Gift: "本活动所赠送的奖金（不含本金）需 {multiplier} 倍有效投注才能提现，(投注仅限{limitData});",
      GiftAndRecharge: "本活动所赠送的奖金 需 {multiplier} 倍有效投注才能提现，奖金+本金(投注仅限{limitData});"
    },
    OFF: {
      Gift: "本活动所赠送的奖金（不含本金）需 {multiplier} 倍有效投注才能提现，(投注不限游戏平台);",
      GiftAndRecharge: "本活动所赠送的奖金 需 {multiplier} 倍有效投注才能提现，奖金+本金(投注不限游戏平台);"
    }
  },
  "en-US": {
    ON: {
      Gift: "The bonus (excluding principal) of this event requires {multiplier} times of valid bets for withdrawal, (betting is limited to {limitData});",
      GiftAndRecharge: "The bonus of this event requires {multiplier} times of valid bets for withdrawal, bonus + principal(betting is limited to {limitData});"
    },
    OFF: {
      Gift: "The bonus (excluding principal) of this event requires {multiplier} times of valid bets for withdrawal, (betting is not limited to game platform);",
      GiftAndRecharge: "The bonus of this event requires {multiplier} times of valid bets for withdrawal, bonus + principal(betting is not limited to game platform);"
    }
  },
  "pt-BR": {
    ON: {
      Gift: "O bônus (excluindo o principal) concedido por esta atividade requer {multiplier} apostas mais eficazes para serem retiradas, (A aposta está limitada a {limitData});",
      GiftAndRecharge: "O bônus concedido por esta atividade requer {multiplier} apostas mais eficazes para serem retiradas, bônus + principal(A aposta está limitada a {limitData});"
    },
    OFF: {
      Gift: "O bônus (excluindo o principal) concedido por esta atividade requer {multiplier} apostas mais eficazes para serem retiradas, (A aposta não está limitada a qualquer plataforma ou jogo);",
      GiftAndRecharge: "O bônus concedido por esta atividade requer {multiplier} apostas mais eficazes para serem retiradas, bônus + principal(A aposta não está limitada a qualquer plataforma ou jogo);"
    }
  },
  "id-ID": {
    ON: {
      Gift: "Bonus yang diberikan dalam kegiatan ini (tidak termasuk modal) harus dipertaruhkan {multiplier} kali lipat untuk dapat ditarik，(Hanya Taruhan{limitData});",
      GiftAndRecharge: "Bonus yang diberikan dalam kegiatan ini harus dipertaruhkan  {multiplier} kali lipat untuk dapat ditarik，Bonus + Modal (Taruhan Hanya{limitData});"
    },
    OFF: {
      Gift: "Bonus yang diberikan dalam kegiatan ini (tidak termasuk modal) harus dipertaruhkan {multiplier} kali lipat untuk dapat ditarik，(Taruhan tidak terbatas pada platform game);",
      GiftAndRecharge: "Bonus yang diberikan dalam kegiatan ini harus dipertaruhkan  {multiplier} kali lipat untuk dapat ditarik，Bonus + Modal(Taruhan tidak terbatas pada platform game);"
    }
  },
  "hi-IN": {
    ON: {
      Gift: "इस प्रमोशन द्वारा दी गई बोनस राशि (मूल राशि को छोड़कर) को निकालने के लिए {multiplier} गुना प्रभावी दांव लगाने की आवश्यकता है, (दांव केवल {limitData} तक सीमित है);",
      GiftAndRecharge: "इस प्रमोशन द्वारा दी गई बोनस राशि को निकालने के लिए {multiplier} गुना प्रभावी दांव लगाने की आवश्यकता है, बोनस + मूल राशि (दांव केवल {limitData} तक सीमित है);"
    },
    OFF: {
      Gift: "इस प्रमोशन द्वारा दी गई बोनस राशि (मूल राशि को छोड़कर) को निकालने के लिए {multiplier} गुना प्रभावी दांव लगाने की आवश्यकता है, (दांव किसी भी गेमिंग प्लेटफ़ॉर्म पर सीमित नहीं है);",
      GiftAndRecharge: "इस प्रमोशन द्वारा दी गई बोनस राशि को निकालने के लिए {multiplier} गुना प्रभावी दांव लगाने की आवश्यकता है, बोनस + मूल राशि (दांव किसी भी गेमिंग प्लेटफ़ॉर्म पर सीमित नहीं है);"
    }
  }
};
//公用的规则模板
const addFiveSixRules: ActivityRulesMap = {
  "zh-CN": {
    5: `本活动仅限账号本人进行正常的人为操作，禁止租借、使用外挂、机器人、不同账号对赌、互刷、套利、接口、协议、利用漏洞、群控或其他技术手段参与，否则将取消或扣除奖励、冻结、甚至拉入黑名单;`,
    6: `为避免文字理解差异，平台将保留本活动最终解释权。`
  },
  "en-US": {
    5: `This activity is limited to the account holder for normal human operations. Renting, using cheats, robots, gambling with different accounts, mutual brushing, arbitrage, interfaces, agreements, exploiting vulnerabilities, group control, or other technical means are prohibited. Otherwise, rewards may be canceled or deducted, frozen, or even blacklisted;`,
    6: `To avoid differences in understanding of the text, the platform reserves the right to interpret this activity finally.`
  },
  "pt-BR": {
    5: `Esta atividade está limitada ao titular da conta para operações humanas normais. Alugar, usar cheats, robôs, jogar com contas diferentes, escovar mutuamente, arbitragem, interfaces, acordos, explorar vulnerabilidades, controle de grupo ou outros meios técnicos são proibidos. Caso contrário, as recompensas podem ser canceladas ou deduzidas, congeladas ou até mesmo colocadas em uma lista negra;`,
    6: `Para evitar diferenças na compreensão do texto, a plataforma reserva o direito de interpretar esta atividade finalmente.`
  },
  "id-ID": {
    5: `Kegiatan ini hanya terbatas pada tindakan manusia normal oleh pemilik akun, dilarang menyewakan, menggunakan cheat, bot, bertaruh dengan akun lain, bertukar-tukar, arbitrase, memanfaatkan antarmuka, protokol, memanfaatkan kerentanan, kontrol kelompok, atau metode teknis lainnya untuk;`,
    6: `Untuk menghindari perbedaan pemahaman teks, platform akan mempertahankan hak interpretasi akhir kegiatan ini.`
  },
  "hi-IN": {
    5: `यह गतिविधि केवल खाता धारक द्वारा सामान्य मानव क्रियाओं तक सीमित है, किराये पर देना, चीट, बॉट, अन्य खातों के साथ सट्टेबाजी, अदला-बदली, आर्बिट्राज, इंटरफ़ेस का उपयोग, प्रोटोकॉल का उपयोग, कमजोरियों का फायदा उठाना, समूह नियंत्रण, या अन्य तकनीकी तरीकों से भाग लेना मना है। अन्यथा, पुरस्कार रद्द या कम कर दिए जाएंगे, खाता फ्रीज़ कर दिया जाएगा, या यहां तक कि ब्लैकलिस्ट में डाल दिया जाएगा;`,
    6: `पाठ की समझ में भिन्नता से बचने के लिए, मंच इस गतिविधि की अंतिम व्याख्या का अधिकार सुरक्षित रखेगा.`
  },
};

// 红包雨模板
const redPacketTemplates: ActivityRulesMap = {
  "zh-CN": {
    1: `神秘矿场每日定时开启{times}次,每次开启持续{duration}分钟,矿场蕴藏{rewardCount}颗水晶,每次开启后玩家们都可免费开采一次,开采到的水晶石自动转换到余额,兑换比例1:1;`,
    2: `每个时间段的矿场都可参与,【请务必在以上时间段登录，以免错过奖励，强烈建议使用手机APP】，若未登录，过期作废（即未主动抢视为自愿放弃）;`,
    3: `活动奖励需自行手动领取,未领取则视为作废;`,
  },
  "en-US": {
    1: `The Mystery Mine opens {times} times at regular intervals every day, with each opening lasting {duration} minutes. The mine contains {rewardCount} crystals, and players can mine once for free after each opening. The mined crystals are automatically converted to balance at a 1:1 exchange rate;`,
    2: `The mine for each time slot can be participated in. [Please be sure to log during the above time slots to avoid missing out on rewards.. It is highly recommended to use the mobile app.] If you are not logged in,  expired (i.e., not actively claimed, it is deemed voluntary abandonment);`,
    3: `Activity rewards must be manually claimed; unclaimed rewards are considered void;`,
  },
  "pt-BR": {
    1: `A Mina Misteriosa abre {times} vezes em intervalos regulares todos os dias, com cada abertura durando {duration} minutos. A mina contém {rewardCount} cristais, e os jogadores podem minerar uma vez de graça após cada abertura. Os cristais minerados são convertidos automaticamente em saldo a uma taxa de câmbio de 1:1;`,
    2: `A mina para cada intervalo de tempo pode ser participada. [Certifique-se de fazer login durante os intervalos de tempo acima para evitar perder recompensas. é altamente recomendável usar o aplicativo móvel.] Se você não estiver logado, expirou (ou seja, não foi reclamado ativamente, é considerado abandono voluntário);`,
    3: `As recompensas da atividade devem ser reivindicadas manualmente; recompensas não reivindicadas são consideradas inválidas;`,
  },
  "id-ID": {
    1: `Tambang Misterius dibuka {times} kali setiap hari, setiap kali dibuka selama {duration} menit, dengan total {rewardCount} kristal tersimpan. Setiap kali dibuka, para pemain dapat menggali secara gratis, kristal yang digali akan otomatis dikonversi menjadi saldo, dengan rasio konversi 1:1;`,
    2: `Setiap periode waktu tambang dapat diikuti, 【Harap pastikan untuk masuk selama periode waktu tersebut, agar tidak melewatkan hadiah, sangat disarankan untuk menggunakan aplikasi ponsel】. Jika tidak masuk, akan hangus (artinya tidak ikut dianggap sebagai pengunduran diri sukarela);`,
    3: `Hadiah kegiatan harus diambil secara manual, jika tidak diambil akan dianggap hangus;`,
  },
  "hi-IN": {
    1: `मिस्ट्री माइन प्रत्येक दिन नियमित अंतराल पर {times} बार खुलती है, प्रत्येक खुलने का समय {duration} मिनट का होता है। खदान में {rewardCount} क्रिस्टल होते हैं, और प्रत्येक खुलने के बाद खिलाड़ी एक बार मुफ्त में खनन कर सकते हैं। खनन किए गए क्रिस्टल को 1:1 विनिमय दर पर स्वचालित रूप से बैलेंस में परिवर्तित कर दिया जाता है;`,
    2: `प्रत्येक समय स्लॉट के लिए खदान में भाग लिया जा सकता है। [कृपया सुनिश्चित करें कि आप उपरोक्त समय स्लॉट के दौरान लॉग इन करें ताकि इनाम से वंचित न हों। मोबाइल ऐप का उपयोग करने की अत्यधिक अनुशंसा की जाती है।] यदि आप लॉग इन नहीं हैं, तो समाप्त (अर्थात, सक्रिय रूप से दावा नहीं किया गया, इसे स्वैच्छिक परित्याग माना जाता है);`,
    3: `गतिविधि के इनाम को मैन्युअल रूप से दावा करना होगा; बिना दावा किए गए इनाम को अमान्य माना जाएगा;`,
  },
};

//签到
const signInTemplates: ActivityRulesMap = {
  "zh-CN": {
    1: `每日进入活动页面即可签到，签到成功可领取对应奖励，最高可得{highestReward}签到奖励;`,
    2: `此活动属于连续签到，中断则需从第一天开始计算，连续签到7天后，第8天时保留当前签到等级，奖励重置;`,
    3: `此活动奖励需自行手动领取，未领取则视为作废;`,
  },
  "en-US": {
    1: `Enter the activity page daily to check in, successfully checking in allows you to claim corresponding rewards, with a maximum of {highestReward} check-in rewards;`,
    2: `This activity is for consecutive check-ins, interruption will require starting counting from the first day, after checking in for 7 consecutive days, on the 8th day, the current check-in level will be retained, and the rewards will reset;`,
    3: `Rewards for this activity must be manually claimed, failure to claim will be considered void;`,
  },
  "pt-BR": {
    1: `Acesse a página da atividade diariamente para fazer check-in, fazer check-in com sucesso permite que você reivindique recompensas correspondentes, com um máximo de {highestReward} recompensas de check-in;`,
    2: `Esta atividade é para check-ins consecutivos, interrupção exigirá começar a contar a partir do primeiro dia, após fazer check-in por 7 dias consecutivos, no 8º dia, o nível de check-in atual será mantido, e as recompensas serão redefinidas;`,
    3: `As recompensas para esta atividade devem ser reivindicadas manualmente, a falha em reivindicar será considerada nula;`,
  },
  "id-ID": {
    1: `Acesse a página da atividade diariamente para fazer check-in, fazer check-in com sucesso permite que você reivindique recompensas correspondentes, com um máximo de {highestReward} recompensas de check-in;`,
    2: `Esta atividade é para check-ins consecutivos, interrupção exigirá começar a contar a partir do primeiro dia, após fazer check-in por 7 dias consecutivos, no 8º dia, o nível de check-in atual será mantido, e as recompensas serão redefinidas;`,
    3: `As recompensas para esta atividade devem ser reivindicadas manualmente, a falha em reivindicar será considerada nula;`,
  },
  "hi-IN": {
    1: `Acesse a página da atividade diariamente para fazer check-in, fazer check-in com sucesso permite que você reivindique recompensas correspondentes, com um máximo de {highestReward} recompensas de check-in;`,
    2: `Esta atividade é para check-ins consecutivos, interrupção exigirá começar a contar a partir do primeiro dia, após fazer check-in por 7 dias consecutivos, no 8º dia, o nível de check-in atual será mantido, e as recompensas serão redefinidas;`,
    3: `As recompensas para esta atividade devem ser reivindicadas manualmente, a falha em reivindicar será considerada nula;`,
  },
};

//代理第一条  是否开启有效投注
const agencyTemplates_first: ActivityRulesMap = {
  "zh-CN": {
    ON: `发展好友领彩金，完成不同人数，可获得对应的彩金，金额最高{highestReward}，发展越多，奖励越高；下级只有在以下平台投注才被视为有效投注；{limitData};`,
    OFF: `发展好友领彩金，完成不同人数，可获得对应的彩金，金额最高{highestReward}，发展越多，奖励越高;`
  },
  "en-US": {
    ON: `Invite friends to receive a bonus. The more friends you invite, the higher the bonus you can earn, with a maximum reward of {highestReward}. The more friends you bring in, the greater the rewards; Bets placed by subordinates are considered valid only on the following platforms；{limitData};`,
    OFF: `Invite friends to receive a bonus. The more friends you invite, the higher the bonus you can earn, with a maximum reward of {highestReward}. The more friends you bring in, the greater the rewards;`
  },
  "pt-BR": {
    ON: `Convide amigos para receber um bônus. Ao completar diferentes números de convites, você pode ganhar o bônus correspondente, com um valor máximo de {highestReward}. Quanto mais amigos você convidar, maiores serão as recompensas; As apostas feitas pelos subordinados são consideradas válidas apenas nas seguintes plataformas；{limitData};`,
    OFF: `Convide amigos para receber um bônus. Ao completar diferentes números de convites, você pode ganhar o bônus correspondente, com um valor máximo de {highestReward}. Quanto mais amigos você convidar, maiores serão as recompensas;`
  },
  "id-ID": {
    ON: `Undang teman untuk menerima bonus. Setelah mencapai jumlah teman yang berbeda, Anda bisa mendapatkan bonus yang sesuai, dengan jumlah maksimal {highestReward}. Semakin banyak teman yang diundang, semakin besar hadiahnya; Hanya taruhan yang dipasang oleh bawahan pada platform berikut yang akan dianggap taruhan sah；{limitData};`,
    OFF: `Undang teman untuk menerima bonus. Setelah mencapai jumlah teman yang berbeda, Anda bisa mendapatkan bonus yang sesuai, dengan jumlah maksimal {highestReward}. Semakin banyak teman yang diundang, semakin besar hadiahnya;`
  },
  "hi-IN": {
    ON: `दोस्तों को आमंत्रित करें और बोनस प्राप्त करें। विभिन्न संख्या में लोगों को पूरा करने पर आपको संबंधित बोनस मिलेगा, जिसकी अधिकतम राशि {highestReward} है। जितने अधिक दोस्तों को आमंत्रित करेंगे, उतना ही अधिक इनाम मिलेगा।; नीचे दिए गए प्लेटफार्मों पर ही की गई सट्टेबाजी को वैध सट्टेबाजी माना जाएगा: {limitData}`,
    OFF: `दोस्तों को आमंत्रित करें और बोनस प्राप्त करें। विभिन्न संख्या में लोगों को पूरा करने पर आपको संबंधित बोनस मिलेगा, जिसकी अधिकतम राशि {highestReward} है। जितने अधिक दोस्तों को आमंत्रित करेंगे, उतना ही अधिक इनाम मिलेगा।;`
  },
};

// 代理
const agencyTemplates: ActivityRulesMap = {
  "zh-CN": {
    2: `此活动属平台额外赠送，同时享有其他代理奖励和佣金，即直接享受多倍快乐;`,
    3: `奖励仅限在iOS、Android、H5端手动领取，过期自动派发;`,
  },
  "en-US": {
    2: `This activity is an additional gift from the platform. You can also enjoy other agent rewards and commissions, which means you can enjoy multiple times the happiness directly;`,
    3: `Rewards can only be claimed manually on iOS, Android, and H5, and will be automatically distributed if expired;`,
  },
  "pt-BR": {
    2: `Esta atividade é um presente adicional da plataforma. Você também pode desfrutar de outras recompensas e comissões de agentes, o que significa que você pode aproveitar a felicidade várias vezes diretamente;`,
    3: `As recompensas só podem ser reivindicadas manualmente no iOS, Android e H5, e serão distribuídas automaticamente se expiradas;`,
  },
  "id-ID": {
    2: `Kegiatan ini merupakan bonus tambahan dari platform, sambil menikmati bonus agen dan komisi lainnya, sehingga langsung menikmati kesenangan ganda;`,
    3: `Hadiah hanya dapat diambil secara manual di platform iOS, Android, dan H5, akan otomatis disebar jika kadaluwarsa;`,
  },
  "hi-IN": {
    2: `यह गतिविधि प्लेटफार्म का एक अतिरिक्त उपहार है। आप अन्य एजेंट इनाम और कमीशन का भी आनंद ले सकते हैं, जिसका मतलब है कि आप सीधे कई गुना खुशियाँ प्राप्त कर सकते हैं;`,
    3: `इनाम केवल iOS, Android, और H5 पर मैन्युअल रूप से दावा किए जा सकते हैं, और समाप्त होने पर स्वचालित रूप से वितरित कर दिए जाएंगे;`,
  },
};

//助力领现金  
const assistanceCashTemplates: ActivityRulesMap = {
  "zh-CN": {
    1: `每天登录可获得{freeDrawCount}次转盘免费抽奖机会，邀请好友免费获得奖励，好友帮助越多，奖励越多，最高可获得 {highestReward} 奖励;`,
    2: `活动期间内，累计总金额达到 {totalAmount}，即可开始提款`,
    3: `每个用户的活动有效期为 {cycleDaily} 天，可多次参与;`,
  },
  "en-US": {
    1: `You can get {freeDrawCount} free spins on the wheel for logging in every day, and you can get rewards for inviting friends for free. The more friends help, the more rewards you get, up to a maximum of {highestReward} reward;`,
    2: `During the event period, once the total accumulated amount reaches {totalAmount}, you can start withdrawing;`,
    3: `The validity period of each user's activity is {cycleDaily} days, and you can participate multiple times;`,
  },
  "pt-BR": {
    1: `Você pode obter {freeDrawCount} giros grátis na roda ao fazer login todos os dias, e você pode obter recompensas por convidar amigos gratuitamente. Quanto mais amigos ajudarem, mais recompensas você recebe, até um máxi {highestReward};`,
    2: `Durante o período do evento, uma vez que o valor total acumulado atinja {totalAmount}, você pode começar a sacar;`,
    3: `O período de validade da atividade de cada usuário é de {cycleDaily} dias, e você pode participar várias vezes;`,
  },
  "id-ID": {
    1: `Setiap hari masuk, Anda akan mendapatkan {freeDrawCount} kesempatan putaran gratis di roda keberuntungan, undang teman untuk mendapatkan hadiah gratis, semakin banyak teman yang membantu, semakin besar hadiahnya, dengan hadiah maksimum {highestReward};`,
    2: `Selama periode kegiatan, jika total akumulasi mencapai {totalAmount}, Anda dapat mulai melakukan penarikan;`,
    3: `Masa berlaku kegiatan untuk setiap pengguna adalah {cycleDaily} hari, dan dapat berpartisipasi beberapa kali;`,
  },
  "hi-IN": {
    1: `हर दिन लॉगिन करने पर आपको {freeDrawCount} बार रूले का मुफ्त ड्रा मौका मिलेगा। दोस्तों को आमंत्रित करके मुफ्त में इनाम प्राप्त करें। जितने अधिक दोस्त मदद करेंगे, उतने अधिक इनाम मिलेंगे, अधिकतम {highestReward} इनाम प्राप्त कर सकते हैं;`,
    2: `गतिविधि की अवधि के दौरान, कुल राशि {totalAmount} तक पहुंचने पर आप निकासी शुरू कर सकते हैं;`,
    3: `प्रत्येक उपयोगकर्ता के लिए गतिविधि की वैधता अवधि {cycleDaily} दिनों की है, और आप कई बार भाग ले सकते हैं;`,
  },
};

// 返水活动 第一条数据
const rebateTemplates_First: ActivityRulesMap_Rebate = {
  "zh-CN": {
    NORECHARGE: '有效投注{lowestRebateBet}或以上,将获得对应比例的打码流水返现金额奖金,投注越多奖励越多,最高可得投注的{highestReward}奖励;',
    RECHARGE: '充值{rechargeLimit}且有效投注{lowestRebateBet}以上,将获得对应比例的打码流水返现奖金,投注越多奖励越多,最高可得投注的{highestReward}奖励;',
  },
  "en-US": {
    NORECHARGE: 'Place bets of {lowestRebateBet} or more to receive a corresponding percentage of cashback bonus based on your total bets. The more you bet, the more rewards you can receive, up to {highestReward} of your total bets;',
    RECHARGE: 'Recharge {rechargeLimit} and wager over {lowestRebateBet} will earn you a corresponding percentage of your wagering turnover as cashback. The more you bet, the more rewards youll receive, with a maximum reward of {highestReward} of your total wagering amount;',
  },
  "pt-BR": {
    NORECHARGE: 'Faça apostas de {lowestRebateBet} ou mais para receber um bônus de cashback correspondente com base em suas apostas totais. Quanto mais você apostar, mais recompensas poderá receber, até {highestReward} do total de suas apostas;',
    RECHARGE: 'Recarregar {rechargeLimit} e apostar mais de {lowestRebateBet} lhe renderá um percentual correspondente do seu volume de apostas como cashback. Quanto mais você apostar, mais recompensas receberá, com uma recompensa máxima de {highestReward} do seu total de apostas;',
  },
  "id-ID": {
    NORECHARGE: 'Taruhan yang valid sebesar {lowestRebateBet} atau lebih akan menerima bonus uang kembali sesuai dengan persentase yang sesuai, semakin banyak taruhan semakin banyak bonus, dengan maksimum {highestReward} dari total taruhan;',
    RECHARGE: 'Dengan mengisi ulang {rechargeLimit} dan taruhan yang valid sebesar {lowestRebateBet} atau lebih, Anda akan menerima bonus uang kembali sesuai dengan persentase yang sesuai, semakin banyak taruhan semakin banyak bonus, dengan maksimum {highestReward} dari total taruhan;',
  },
  "hi-IN": {
    NORECHARGE: 'मान्य दांव {lowestRebateBet} या उससे अधिक पर, आपको संबंधित प्रतिशत का कैशबैक बोनस मिलेगा। जितना अधिक दांव, उतना अधिक इनाम। अधिकतम आप {highestReward} का इनाम प्राप्त कर सकते हैं;',
    RECHARGE: 'रिचार्ज {rechargeLimit} और मान्य दांव {lowestRebateBet} या उससे अधिक पर, आपको संबंधित प्रतिशत का कैशबैक बोनस मिलेगा। जितना अधिक दांव, उतना अधिक इनाम। अधिकतम आप {highestReward} का इनाम प्राप्त कर सकते हैं;',
  },
};

// 返水活动 二 三  条数据
const rebateTemplates_Other: ActivityRulesMap = {
  "zh-CN": {
    2: `活动期间,可重复参与,领取奖励后有效投注累计清零,可继续游戏获得奖励;`,
    3: `奖励仅限在iOS、Android、H5端手动领取，过期作废;`,
  },
  "en-US": {
    2: `You can participate in the activity multiple times during the promotion period. Your total bets will reset to zero after claiming the reward, and you can continue to play to earn more rewards;`,
    3: `Rewards can only be claimed manually on iOS, Android, and H5, and will expire if not claimed in time;`,
  },
  "pt-BR": {
    2: `Você pode participar da atividade várias vezes durante o período de promoção. Suas apostas totais serão redefinidas para zero após reivindicar a recompensa, e você pode continuar jogando para ganhar mais recompensas;`,
    3: `As recompensas só podem ser reivindicadas manualmente no iOS, Android e H5, e expirarão se não forem reclamadas a tempo;`,
  },
  "id-ID": {
    2: `Selama periode kegiatan, Anda dapat berpartisipasi secara berulang, total taruhan yang valid akan direset setelah menerima hadiah, dan Anda dapat melanjutkan permainan untuk mendapatkan hadiah;`,
    3: `Hadiah hanya dapat diambil secara manual di platform iOS, Android, dan H5, akan otomatis disebar jika kadaluwarsa;`,
  },
  "hi-IN": {
    2: `गतिविधि की अवधि के दौरान, आप बार-बार भाग ले सकते हैं। इनाम प्राप्त करने के बाद, मान्य दांव की कुल राशि शून्य हो जाएगी और आप खेल जारी रख सकते हैं और अधिक इनाम प्राप्त कर सकते हैं;`,
    3: `इनाम केवल iOS, Android, H5 पर ही मैन्युअल रूप से प्राप्त किए जा सकते हैं। समाप्ति के बाद अमान्य हो जाएगा;`,
  },
};
//充值活动模板  第一条数据
const rechargeTemplates_First: ActivityRulesMap_Recharge = {
  "zh-CN": {
    FIRST: {
      NONE: "账号首次充值专享，只有一次机会，充值越多，奖励越多，最高奖励{highestReward};",
      DAILY: "活动期间，每日首次充值将获得不同程度的奖励，充值越多，奖励越多，最高奖励{highestReward};",
      WEEKLY: "活动期间，每周首次充值将获得不同程度的奖励，充值越多，奖励越多，最高奖励{highestReward};",
    },
    SINGLE: {
      NONE: "活动期间，单笔充值将获得不同程度的奖励（此活动可重复参与），充值越多，奖励越多，最高奖励{highestReward};",
      DAILY: "活动期间，单笔充值将获得不同程度的奖励（此活动可重复参与），充值越多，奖励越多，最高奖励{highestReward};",
      WEEKLY: "活动期间，单笔充值将获得不同程度的奖励（此活动可重复参与），充值越多，奖励越多，最高奖励{highestReward};",
    },
    SUM: {
      NONE: "活动期间，累计充值将获得不同程度的奖励，充值越多，奖励越多，最高奖励{highestReward}，每个阶梯可获得一次奖励;",
      DAILY: "活动期间，每日累计充值将获得不同程度的奖励，充值越多，奖励越多，最高奖励{highestReward}，每个阶梯可获得一次奖励;",
      WEEKLY: "活动期间，每周累计充值将获得不同程度的奖励，充值越多，奖励越多，最高奖励{highestReward};",
    },
  },
  // 英语
  "en-US": {
    FIRST: {
      NONE: "Account's first recharge exclusive, only one chance, the more you recharge, the more rewards you get, with the maximum reward being {highestReward};",
      DAILY: "During the event, a single recharge will receive rewards of varying degrees (this event can be participated in repeatedly). the more you recharge, the more rewards you will receive, with the maximum reward being {highestReward};",
      WEEKLY: "During the event, the first recharge each week will receive rewards of varying degrees, the more rewards you get, with the maximum reward being {highestReward};"
    },
    SINGLE: {
      NONE: "During the event, a single recharge will receive rewards of varying degrees (this event can be participated in repeatedly). the more you recharge, the more rewards you will receive, with the maximum reward being {highestReward};",
      DAILY: "During the event, a single recharge will receive rewards of varying degrees (this event can be participated in repeatedly). the more you recharge, the more rewards you will receive, with the maximum reward being {highestReward};",
      WEEKLY: "During the event period, each single recharge per week will receive rewards of varying degrees  (this event can be participated in repeatedly),the more you recharge, the more rewards you will receive, with the maximum reward being {highestReward};"
    },
    SUM: {
      NONE: "During the event period, you will receive rewards based on the total amount of recharge,the more you recharge, the more rewards you will receive, with the maximum reward being {highestReward},each tier can receive a reward once;",
      DAILY: "During the event period, you will receive rewards based on the total amount of recharge,the more you recharge, the more rewards you will receive, with the maximum reward being {highestReward},each tier can receive a reward once;",
      WEEKLY: "During the event, different rewards will be given based on the cumulative recharge amount each week, the more rewards you will receive, with the maximum reward being {highestReward},each tier can receive a reward once;"
    },
  },
  // 葡萄牙语
  "pt-BR": {
    FIRST: {
      NONE: "Recarga exclusiva da conta, apenas uma chance, quanto mais você recarrega, mais recompensas você recebe, com uma recompensa máxima de {highestReward};",
      DAILY: "Durante o evento, a primeira recarga de cada dia receberá recompensas de graus variados. Quanto mais você recarregar, mais recompensas receberá, com uma recompensa máx {highestReward};",
      WEEKLY: "Durante o evento, a primeira recarga de cada semana receberá recompensas de graus variados, quanto mais recargas você obtiver, com uma recompensa máx {highestReward};"
    },
    SINGLE: {
      NONE: "Durante o evento, uma única recarga receberá recompensas de graus variados (este evento pode ser participado repetidamente). quanto mais você recarregar, mais recompensas receberá, com uma recompensa máx {highestReward};",
      DAILY: "Durante o evento, uma única recarga receberá recompensas de graus variados (este evento pode ser participado repetidamente). quanto mais você recarregar, mais recompensas receberá, com uma recompensa máx {highestReward};",
      WEEKLY: "Durante o evento, uma única recarga receberá recompensas de graus variados (este evento pode ser participado repetidamente). quanto mais você recarregar, mais recompensas receberá, com uma recompensa máx {highestReward};"
    },
    SUM: {
      NONE: "Durante o período do evento, você receberá recompensas com base no valor total da recarga, quanto mais você recarregar, mais recompensas receberá, com uma recompensa máx {highestReward}, cada nível pode receber uma recompensa uma vez;",
      DAILY: "Durante o período do evento, você receberá recompensas com base no valor total da recarga, quanto mais você recarregar, mais recompensas receberá, com uma recompensa máx {highestReward}, cada nível pode receber uma recompensa uma vez;",
      WEEKLY: "Durante o evento, serão dados diferentes prêmios com base no valor da recarga acumulada a cada semana, quanto mais você recarregar, mais prêmios receberá, com um prêmio máx {highestReward}, cada nível pode receber um prêmio uma vez;"
    },
  },
  //印尼语
  "id-ID": {
    FIRST: {
      NONE: "Eksklusif untuk Pengisian Pertama Akun, hanya satu kesempatan, semakin banyak pengisian, semakin banyak hadiahnya, hadiah tertinggi {highestReward};",
      DAILY: "Selama periode promosi, setiap pengisian pertama setiap hari akan mendapatkan hadiah dalam tingkat yang berbeda, semakin banyak pengisian, semakin banyak hadiah, hadiah tertinggi {highestReward};",
      WEEKLY: "Selama periode promosi, pengisian pertama setiap minggu akan mendapatkan hadiah dalam tingkat yang berbeda, semakin banyak pengisian, semakin banyak hadiah, hadiah tertinggi {highestReward};"
    },
    SINGLE: {
      NONE: "Selama periode promosi, pengisian tunggal akan mendapatkan hadiah dalam tingkat yang berbeda (kegiatan ini dapat diikuti secara berulang), semakin banyak pengisian, semakin banyak hadiah, hadiah tertinggi {highestReward};",
      DAILY: "Selama periode aktivitas, setiap pengisian tunggal akan mendapatkan hadiah dengan tingkat yang berbeda (aktivitas ini dapat diikuti secara berulang), semakin banyak pengisian, semakin besar hadiahnya, hadiah tertinggi {highestReward};",
      WEEKLY: "Selama periode aktivitas, setiap pengisian tunggal akan mendapatkan hadiah dengan tingkat yang berbeda (aktivitas ini dapat diikuti secara berulang), semakin banyak pengisian, semakin besar hadiahnya, hadiah tertinggi {highestReward};"
    },
    SUM: {
      NONE: "Selama periode aktivitas, pengisian total akan mendapatkan hadiah dengan tingkat yang berbeda, semakin banyak pengisian, semakin besar hadiahnya, hadiah tertinggi {highestReward}，Setiap tangga dapat mendapatkan satu kali hadiah;",
      DAILY: "Selama periode aktivitas, pengisian harian akan mendapatkan hadiah dengan tingkat yang berbeda, semakin banyak pengisian, semakin besar hadiahnya, hadiah tertinggi {highestReward}，Setiap tangga dapat mendapatkan satu kali hadiah;",
      WEEKLY: "Selama periode aktivitas, pengisian mingguan akan mendapatkan hadiah dengan tingkat yang berbeda, semakin banyak pengisian, semakin besar hadiahnya, hadiah tertinggi {highestReward};"
    },
  },
  //印地语
  "hi-IN": {
    FIRST: {
      NONE: "खाता पहली जमा के लिए विशेष, केवल एक बार मौका, जितनी अधिक जमा, उतना अधिक इनाम, अधिकतम इनाम {highestReward};",
      DAILY: "प्रमोशन अवधि के दौरान, प्रतिदिन पहली जमा पर विभिन्न स्तर के इनाम प्राप्त होंगे, जितनी अधिक जमा, उतना अधिक इनाम, अधिकतम इनाम {highestReward};",
      WEEKLY: "Fप्रमोशन अवधि के दौरान, साप्ताहिक पहली जमा पर विभिन्न स्तर के इनाम प्राप्त होंगे, जितनी अधिक जमा, उतना अधिक इनाम, अधिकतम इनाम {highestReward};"
    },
    SINGLE: {
      NONE: "प्रमोशन अवधि के दौरान, एकल लेनदेन पर विभिन्न स्तर के इनाम प्राप्त होंगे (इस गतिविधि में बार-बार भाग लिया जा सकता है), जितनी अधिक जमा, उतना अधिक इनाम, अधिकतम इनाम {highestReward};",
      DAILY: "प्रमोशन अवधि के दौरान, एकल लेनदेन पर विभिन्न स्तर के इनाम प्राप्त होंगे (इस गतिविधि में बार-बार भाग लिया जा सकता है), जितनी अधिक जमा, उतना अधिक इनाम, अधिकतम इनाम {highestReward};",
      WEEKLY: "प्रमोशन अवधि के दौरान, एकल लेनदेन पर विभिन्न स्तर के इनाम प्राप्त होंगे (इस गतिविधि में बार-बार भाग लिया जा सकता है), जितनी अधिक जमा, उतना अधिक इनाम, अधिकतम इनाम {highestReward};"
    },
    SUM: {
      NONE: "प्रमोशन अवधि के दौरान, संचयी जमा पर विभिन्न स्तर के इनाम प्राप्त होंगे, जितनी अधिक जमा, उतना अधिक इनाम, अधिकतम इनाम {highestReward}, प्रत्येक स्तर पर एक बार इनाम प्राप्त होगा;",
      DAILY: "प्रमोशन अवधि के दौरान, प्रतिदिन संचयी जमा पर विभिन्न स्तर के इनाम प्राप्त होंगे, जितनी अधिक जमा, उतना अधिक इनाम, अधिकतम इनाम {highestReward}, प्रत्येक स्तर पर एक बार इनाम प्राप्त होगा;",
      WEEKLY: "प्रमोशन अवधि के दौरान, साप्ताहिक संचयी जमा पर विभिन्न स्तर के इनाम प्राप्त होंगे, जितनी अधिक जमा, उतना अधिक इनाम, अधिकतम इनाम {highestReward};"
    },
  },
};

// 充值活动  二   条数据
const rechargeTemplates_Two: ActivityRulesMap = {
  "zh-CN": {
    2: `充值不限制方式，奖励预计10分钟后更新，请等待奖励发放;`,
  },
  "en-US": {
    2: `Recharge methods are not limited, rewards are expected to be updated after 10 minutes, please wait for the rewards to be issued;`,
  },
  "pt-BR": {
    2: `Os métodos de recarga não são limitados, as recompensas devem ser atualizadas após 10 minutos, por favor, aguarde que as recompensas sejam emitidas;`,
  },
  "id-ID": {
    2: `Tidak ada batasan cara pengisian, hadiah diperkirakan akan diperbarui dalam 10 menit, harap tunggu hadiahnya disalurkan;`,
  },
  "hi-IN": {
    2: `रिचार्ज विधियाँ सीमित नहीं हैं, इनाम अद्यतन होने की उम्मीद है 10 मिनट के बाद, कृपया इनाम जारी होने की प्रतीक्षा करें;`,
  },
};

// 充值活动  三   条数据
const rechargeTemplates_Three: ActivityRulesMap = {
  "zh-CN": {
    ACTIVITY: "奖励仅限在iOS、Android、H5端手动领取，过期自动派发;",
    BALANCE: "获得的奖励直接派发到账;"
  },
  "en-US": {
    ACTIVITY: "Rewards can only be claimed manually on iOS, Android, and H5, and will be automatically distributed if expired;",
    BALANCE: "The rewards will be directly credited to your account;"
  },
  "pt-BR": {
    ACTIVITY: "As recompensas só podem ser reivindicadas manualmente no iOS, Android e H5, e serão distribuídas automaticamente se expiradas;",
    BALANCE: "As recompensas serão creditadas diretamente em sua conta;"
  },
  "id-ID": {
    ACTIVITY: "Hadiah hanya dapat diambil secara manual di platform iOS, Android, dan H5, akan otomatis disebar jika kadaluwarsa;",
    BALANCE: "Hadiah yang diperoleh langsung didistribusikan ke akun;"
  },
  "hi-IN": {
    ACTIVITY: "इनाम केवल iOS, Android, और H5 पर मैन्युअल रूप से दावा किए जा सकते हैं, और समाप्त होने पर स्वचालित रूप से वितरित कर दिए जाएंगे;",
    BALANCE: "प्राप्त इनाम सीधे आपके खाते में जमा किया जाएगा;"
  }
};

// 幸运转盘 第一 二条数据
const luckyWheelTemplates_firstSecond: ActivityRulesMap = {
  "zh-CN": {
    1: `完成转盘任务,可获得对应的抽奖券,每次抽奖消耗1张抽奖券;`,
    2: `收集"H","A","P","P","Y", 每收集一套则可兑换一次{exchangeReward}奖励;`,
  },
  "en-US": {
    1: `Complete the roulette task to receive corresponding lottery tickets, with each draw costing 1 ticket;`,
    2: `Collect "H", "A", "P", "P", "Y", and exchange each set for a prize of {exchangeReward};`,
  },
  "pt-BR": {
    1: `Conclua a tarefa da roleta para receber bilhetes de loteria correspondentes, sendo que cada sorteio custa 1 bilhete;`,
    2: `Colete "H", "A", "P", "P", "Y" e troque cada conjunto por um prêmio de {exchangeReward};`,
  },
  "id-ID": {
    1: `Menyelesaikan misi Putar Roda akan mendapatkan kupon undian sesuai, setiap kali mengundih akan menghabiskan 1 kupon undian;`,
    2: `Kumpulkan 'H','A','P','P','Y', setiap set yang dikumpulkan dapat ditukarkan dengan hadiah {exchangeReward} sekali;`,
  },
  "hi-IN": {
    1: `लॉटरी टिकट प्राप्त करने के लिए रूले कार्य को पूरा करें, प्रत्येक ड्रा के लिए 1 टिकट खर्च होता है;`,
    2: `"H", "A", "P", "P", "Y" इकट्ठा करें और प्रत्येक सेट को {exchangeReward} के इनाम के लिए बदलें;`,
  },
};

// 幸运转盘 第三条数据
const luckyWheelTemplates_third: ActivityRulesMap_Rebate = {
  "zh-CN": {
    NONE: `奖励仅限在iOS、Android、H5手动领取，未领取奖励依然保留，直至活动关闭或者活动结束时，奖励将作废;`,
    DAILY: `活动期间,今日未使用的抽奖券与兑换券,则次日00:00会保留;`,
    WEEKLY: `活动期间,本周未使用的抽奖券与兑换券,则下周一00:00会保留;`,
  },
  "en-US": {
    NONE: `The rewards can only be claimed manually on iOS, Android, and H5 platforms. Unclaimed rewards will remain available until the event is closed or ends, at which point the rewards will be forfeited;`,
    DAILY: `During the event, any unused lottery tickets and exchange vouchers from today will be retained at 00:00 the next day;`,
    WEEKLY: `During the event, any unused lottery tickets and exchange vouchers from this week will be retained at 00:00 on Monday of the following week;`,
  },
  "pt-BR": {
    NONE: `As recompensas só podem ser resgatadas manualmente nas plataformas iOS, Android e H5. As recompensas não resgatadas permanecerão disponíveis até que o evento seja encerrado ou termine; após isso, as recompensas serão anuladas;`,
    DAILY: `Durante o evento, qualquer bilhete de loteria e voucher de troca não utilizados hoje serão mantidos às 00:00 do dia seguinte;`,
    WEEKLY: `Durante o evento, qualquer bilhete de loteria e voucher de troca não utilizados nesta semana serão mantidos às 00:00 na segunda-feira da próxima semana;`,
  },
  "id-ID": {
    NONE: `Hadiah hanya dapat diambil secara manual di platform iOS, Android, dan H5. Hadiah yang tidak diambil akan tetap tersedia sampai acara ditutup atau berakhir, setelah itu hadiah akan hangus;`,
    DAILY: `Selama acara berlangsung, kupon undian dan kupon penukaran yang tidak digunakan hari ini akan disimpan pada pukul 00:00 hari berikutnya;`,
    WEEKLY: `Selama acara berlangsung, kupon undian dan kupon penukaran yang tidak digunakan minggu ini akan disimpan pada pukul 00:00 hari Senin minggu depan;`,
  },
  "hi-IN": {
    NONE: `पुरस्कार केवल iOS, Android, और H5 प्लेटफार्म पर मैन्युअल रूप से प्राप्त किए जा सकते हैं। प्राप्त न किए गए पुरस्कार तब तक उपलब्ध रहेंगे जब तक कि कार्यक्रम बंद नहीं हो जाता या समाप्त नहीं हो जाता; उसके बाद पुरस्कार रद्द कर दिए जाएंगे।;`,
    DAILY: `कार्यक्रम के दौरान, आज के अप्रयुक्त लॉटरी टिकट और विनिमय कूपन अगले दिन 00:00 बजे सुरक्षित रहेंगे।;`,
    WEEKLY: `कार्यक्रम के दौरान, इस सप्ताह के अप्रयुक्त लॉटरी टिकट और विनिमय कूपन अगले सप्ताह के सोमवार को 00:00 बजे तक सुरक्षित रहेंगे।;`,
  },
};

const assistanceTemplates_first: ActivityRulesMap_Recharge = {
  "zh-CN": {
    DAILY: {
      FIXED: `此活动属于"人道救援，江湖救急"，当您昨日在游戏中亏损{lowestLoss}或以上，即可获得对应金额的救援金，奖金高低由亏损金额决定，亏损越多，救援金额越高，最高 {highestReward};`,
      RANGE: `此活动属于"人道救援，江湖救急"，当您昨日在游戏中亏损{lowestLoss}或以上，即可获得对应金额的救援金，奖金高低由亏损金额决定，亏损越多，救援金额越高，最高 {highestReward}%;`
    },
    WEEKLY: {
      FIXED: `此活动属于"人道救援，江湖救急"，当您上周在游戏中亏损{lowestLoss}或以上，即可获得对应金额的救援金，奖金高低由亏损金额决定，亏损越多，救援金额越高，最高 {highestReward};`,
      RANGE: `此活动属于"人道救援，江湖救急"，当您上周在游戏中亏损{lowestLoss}或以上，即可获得对应金额的救援金，奖金高低由亏损金额决定，亏损越多，救援金额越高，最高 {highestReward}%;`
    }
  },
  "en-US": {
    DAILY: {
      FIXED: `This activity belongs to "Humanitarian Rescue, Rivers and Lakes Emergency Rescue". When you Yesterday lose {lowestLoss} or more in the game, you can get the corresponding amount of rescue money. The level of the bonus is determined by the amount of the loss. The more the loss, the higher the rescue amount, up to {highestReward};`,
      RANGE: `This activity belongs to "Humanitarian Rescue, Rivers and Lakes Emergency Rescue". When you Yesterday lose {lowestLoss} or more in the game, you can get the corresponding amount of rescue money. The level of the bonus is determined by the amount of the loss. The more the loss, the higher the rescue amount, up to {highestReward}%;`
    },
    WEEKLY: {
      FIXED: `This activity belongs to "Humanitarian Rescue, Rivers and Lakes Emergency Rescue". When you last week lose {lowestLoss} or more in the game, you can get the corresponding amount of rescue money. The level of the bonus is determined by the amount of the loss. The more the loss, the higher the rescue amount, up to {highestReward};`,
      RANGE: `This activity belongs to "Humanitarian Rescue, Rivers and Lakes Emergency Rescue". When you last week lose {lowestLoss} or more in the game, you can get the corresponding amount of rescue money. The level of the bonus is determined by the amount of the loss. The more the loss, the higher the rescue amount, up to {highestReward}%;`
    }
  },
  "pt-BR": {
    DAILY: {
      FIXED: `Este evento pertence a "resgate humanitário, ajuda urgente nos rios e lagos". Quando você perde {lowestLoss} ou mais no jogo ontem, você pode obter o valor correspondente de dinheiro de socorro. A quantidade de bônus depende da quantidade de perda. Quanto mais você perde, maior o valor do dinheiro de socorro, até {highestReward};`,
      RANGE: `Este evento pertence a "resgate humanitário, ajuda urgente nos rios e lagos". Quando você perde {lowestLoss} ou mais no jogo ontem, você pode obter o valor correspondente de dinheiro de socorro. A quantidade de bônus depende da quantidade de perda. Quanto mais você perde, maior o valor do dinheiro de socorro, até {highestReward}%;`
    },
    WEEKLY: {
      FIXED: `Esta atividade pertence a "Resgate Humanitário, Resgate de Emergência em Rios e Lagos". Quando você perde {lowestLoss} ou mais na semana passada no jogo, você pode obter o valor correspondente de dinheiro de resgate. O nível do bônus é determinado pelo valor da perda. Quanto maior a perda, maior o valor do resgate, até {highestReward};`,
      RANGE: `Esta atividade pertence a "Resgate Humanitário, Resgate de Emergência em Rios e Lagos". Quando você perde {lowestLoss} ou mais na semana passada no jogo, você pode obter o valor correspondente de dinheiro de resgate. O nível do bônus é determinado pelo valor da perda. Quanto maior a perda, maior o valor do resgate, até {highestReward}%;`
    }
  },
  "id-ID": {
    DAILY: {
      FIXED: `Kegiatan ini merupakan "Bantuan Kemanusiaan, Pertolongan di Dunia Jianghu", ketika Anda mengalami kerugian {lowestLoss} atau lebih dalam permainan kemarin, Anda akan menerima bantuan uang sejumlah yang sesuai. Tingkat hadiah ditentukan oleh jumlah kerugian, semakin besar kerugian, semakin tinggi jumlah bantuan, dengan jumlah bantuan maksimum {highestReward};`,
      RANGE: `Kegiatan ini merupakan "Bantuan Kemanusiaan, Pertolongan di Dunia Jianghu", ketika Anda mengalami kerugian {lowestLoss} atau lebih dalam permainan kemarin, Anda akan menerima bantuan uang sejumlah yang sesuai. Tingkat hadiah ditentukan oleh jumlah kerugian, semakin besar kerugian, semakin tinggi jumlah bantuan, dengan jumlah bantuan maksimum {highestReward}%;`
    },
    WEEKLY: {
      FIXED: `Kegiatan ini merupakan "Bantuan Kemanusiaan, Pertolongan di Dunia Jianghu", ketika Anda mengalami kerugian {lowestLoss} atau lebih dalam permainan minggu lalu, Anda akan menerima bantuan uang sejumlah yang sesuai. Tingkat hadiah ditentukan oleh jumlah kerugian, semakin besar kerugian, semakin tinggi jumlah bantuan, dengan jumlah bantuan maksimum {highestReward};`,
      RANGE: `Kegiatan ini merupakan "Bantuan Kemanusiaan, Pertolongan di Dunia Jianghu", ketika Anda mengalami kerugian {lowestLoss} atau lebih dalam permainan minggu lalu, Anda akan menerima bantuan uang sejumlah yang sesuai. Tingkat hadiah ditentukan oleh jumlah kerugian, semakin besar kerugian, semakin tinggi jumlah bantuan, dengan jumlah bantuan maksimum {highestReward}%;`
    }
  },
  "hi-IN": {
    DAILY: {
      FIXED: `यह गतिविधि "मानवीय बचाव, आपातकालीन सहायता" के अंतर्गत आती है। जब आप कल के खेल में {lowestLoss} से अधिक की हानि करते हैं, तो आप संबंधित राशि की सहायता राशि प्राप्त कर सकते हैं। बोनस की मात्रा हानि की मात्रा पर निर्भर करती है। जितनी अधिक हानि होगी, सहायता राशि उतनी ही अधिक होगी, अधिकतम {highestReward}  तक;`,
      RANGE: `यह गतिविधि "मानवीय बचाव, आपातकालीन सहायता" के अंतर्गत आती है। जब आप कल के खेल में {lowestLoss} से अधिक की हानि करते हैं, तो आप संबंधित राशि की सहायता राशि प्राप्त कर सकते हैं। बोनस की मात्रा हानि की मात्रा पर निर्भर करती है। जितनी अधिक हानि होगी, सहायता राशि उतनी ही अधिक होगी, अधिकतम {highestReward}%  तक ;`
    },
    WEEKLY: {
      FIXED: `यह गतिविधि "मानवीय बचाव, नदियों और झीलों में आपातकालीन बचाव" से संबंधित है। जब आप पिछले सप्ताह खेल में {lowestLoss} या उससे अधिक हारते हैं, तो आप संबंधित राशि का बचाव धन प्राप्त कर सकते हैं। बोनस का स्तर हानि की राशि द्वारा निर्धारित किया जाता है। जितनी अधिक हानि होगी, उतनी ही अधिक बचाव राशि होगी, अधिकतम {highestReward} तक;`,
      RANGE: `यह गतिविधि "मानवीय बचाव, नदियों और झीलों में आपातकालीन बचाव" से संबंधित है। जब आप पिछले सप्ताह खेल में {lowestLoss} या उससे अधिक हारते हैं, तो आप संबंधित राशि का बचाव धन प्राप्त कर सकते हैं। बोनस का स्तर हानि की राशि द्वारा निर्धारित किया जाता है। जितनी अधिक हानि होगी, उतनी ही अधिक बचाव राशि होगी, अधिकतम {highestReward}% तक;`
    }
  },
};

const assistanceTemplates_second: ActivityRulesMap_Recharge = {
  "zh-CN": {
    FIXED: {
      EXCLUDE_GIFT: '计算公式：赠送金额= 周期内亏损金额（扣除领取的优惠）对应阶梯奖金;',
      NORMAL: '计算公式：赠送金额= 周期内亏损金额对应阶梯奖金;',
    },
    RANGE: {
      EXCLUDE_GIFT: '计算公式： 奖励金额 = 当期损失金额（扣除领取的优惠）x 返点率;',
      NORMAL: '计算公式： 奖励金额 = 当期损失金额 x 返点率;',
    },
  },
  "en-US": {
    FIXED: {
      EXCLUDE_GIFT: 'Calculation formula: The bonus amount = Loss amount within the period（Deducting received discounts）Corresponding tier bonus;',
      NORMAL: 'Calculation formula: The bonus amount = Loss amount within the period Corresponding tier bonus;',
    },
    RANGE: {
      EXCLUDE_GIFT: 'Calculation formula: Bonus amount = Loss amount within the period（Deducting received discounts）x Rebate rate;',
      NORMAL: 'Calculation formula: Bonus amount = Loss amount within the period x Rebate rate;',
    },
  },
  "pt-BR": {
    FIXED: {
      EXCLUDE_GIFT: 'Fórmula de cálculo: O valor do bônus = Valor da perda dentro do período (Dedução dos descontos recebidos) Bônus da escada correspondente;',
      NORMAL: 'Fórmula de cálculo: O valor do bônus = Valor da perda dentro do período Bônus da escada correspondente;',
    },
    RANGE: {
      EXCLUDE_GIFT: 'Fórmula de cálculo: Valor do bônus = Valor da perda dentro do período (Dedução dos descontos recebidos) x Taxa de rebate;',
      NORMAL: 'Fórmula de cálculo: Valor do bônus = Valor da perda dentro do período x Taxa de rebate;',
    },
  },
  "id-ID": {
    FIXED: {
      EXCLUDE_GIFT: 'Rumus Perhitungan: Jumlah Hadiah = Jumlah Kerugian dalam Periode (Setelah Dikurangi Diskon yang Diterima) x Jumlah Hadiah pada Tingkat yang Sama;',
      NORMAL: 'Rumus Perhitungan: Jumlah Hadiah = Jumlah Kerugian dalam Periode x Jumlah Hadiah pada Tingkat yang Sama;',
    },
    RANGE: {
      EXCLUDE_GIFT: 'Rumus Perhitungan: Jumlah Hadiah = Jumlah Kerugian Periode Ini (Setelah Dikurangi Diskon yang Diterima) x Tingkat Cashback;',
      NORMAL: 'Rumus Perhitungan: Jumlah Hadiah = Jumlah Kerugian Periode Ini x Tingkat Cashback;',
    },
  },
  "hi-IN": {
    FIXED: {
      EXCLUDE_GIFT: 'गणना सूत्र: बोनस राशि = अवधि के भीतर हानि राशि (प्राप्त छूटों की कटौती) संबंधित स्तर का बोनस;',
      NORMAL: 'गणना सूत्र: बोनस राशि = अवधि के भीतर हानि राशि संबंधित स्तर का बोनस;',
    },
    RANGE: {
      EXCLUDE_GIFT: 'गणना सूत्र: बोनस राशि = अवधि के भीतर हानि राशि (प्राप्त छूटों की कटौती) x रिबेट दर;',
      NORMAL: 'गणना सूत्र: बोनस राशि = अवधि के भीतर हानि राशि x रिबेट दर;',
    },
  },
};

// 救援金第三条
const rewardDistributionMapWithDelay = {
  "zh-CN": {
    // 每日
    DAILY: {
      ACTIVITY: {   //手动领取
        IMMEDIATELY: "获得的奖励次日 00:00:00 以后才可领取;",     //立即到账
        DELAY: "获得的奖励次日 {time} 以后才可领取;",              //延迟到账
      },
      BALANCE: {     //自动领取
        IMMEDIATELY: "获得的奖励次日 00:00:00 以后才可领取;",
        DELAY: "获得的奖励次日 {time} 以后才可领取;",
      },
    },
    // 每周
    WEEKLY: {
      ACTIVITY: {
        IMMEDIATELY: "获得的奖励下周一 00:00:00 以后才可领取;",
        DELAY: "获得的奖励下周一 {time} 以后才可领取;",
      },
      BALANCE: {
        IMMEDIATELY: "获得的奖励下周一 00:00:00 以后才可领取;",
        DELAY: "获得的奖励下周一 {time} 以后才可领取;",
      },
    },
  },
  "en-US": {
    DAILY: {
      ACTIVITY: {
        IMMEDIATELY: "The rewards can be claimed starting from Next day 00:00:00 later;",
        DELAY: "The rewards can be claimed starting from Next day {time} later;",
      },
      BALANCE: {
        IMMEDIATELY: "The rewards can be claimed starting from Next day 00:00:00 later;",
        DELAY: "The rewards can be claimed starting from Next day {time} later;",
      },
    },
    WEEKLY: {
      ACTIVITY: {
        IMMEDIATELY: "The rewards can be claimed starting from Next Monday 00:00:00 later;",
        DELAY: "The rewards can be claimed starting from Next Monday {time} later;",
      },
      BALANCE: {
        IMMEDIATELY: "The rewards can be claimed starting from Next Monday 00:00:00 later;",
        DELAY: "The rewards can be claimed starting from Next Monday {time} later;",
      },
    },
  },
  "pt-BR": {
    DAILY: {
      ACTIVITY: {
        IMMEDIATELY: "As recompensas podem ser reivindicadas a partir do próximo dia às 00:00:00;",
        DELAY: "As recompensas podem ser reivindicadas a partir do próximo dia às {time};",
      },
      BALANCE: {
        IMMEDIATELY: "As recompensas podem ser reivindicadas a partir do próximo dia às 00:00:00;",
        DELAY: "As recompensas podem ser reivindicadas a partir do próximo dia às {time};",
      },
    },
    WEEKLY: {
      ACTIVITY: {
        IMMEDIATELY: "As recompensa pode ser reclamada a partir da próxima segunda-feira às 00:00:00;",
        DELAY: "As recompensa pode ser reclamada a partir da próxima segunda-feira às {time};",
      },
      BALANCE: {
        IMMEDIATELY: "As recompensa pode ser reclamada a partir da próxima segunda-feira às 00:00:00;",
        DELAY: "As recompensa pode ser reclamada a partir da próxima segunda-feira às {time};",
      },
    },
  },
  "id-ID": {
    DAILY: {
      ACTIVITY: {
        IMMEDIATELY: "Hadiah yang diperoleh baru bisa diambil setelah pukul 00:00:00 hari berikutnya;",
        DELAY: "Hadiah yang diperoleh baru bisa diambil setelah {time} hari berikutnya;",
      },
      BALANCE: {
        IMMEDIATELY: "Hadiah yang diperoleh baru bisa diambil setelah pukul 00:00:00 hari berikutnya;",
        DELAY: "Hadiah yang diperoleh baru bisa diambil setelah {time} hari berikutnya;",
      },
    },
    WEEKLY: {
      ACTIVITY: {
        IMMEDIATELY: "Hadiah yang diperoleh baru bisa diambil setelah pukul 00:00:00 hari Senin depan;",
        DELAY: "Hadiah yang diperoleh baru bisa diambil setelah {time} hari Senin depan;",
      },
      BALANCE: {
        IMMEDIATELY: "Hadiah yang diperoleh baru bisa diambil setelah pukul 00:00:00 hari Senin depan;",
        DELAY: "Hadiah yang diperoleh baru bisa diambil setelah {time} hari Senin depan;",
      },
    },
  },
  "hi-IN": {
    DAILY: {
      ACTIVITY: {
        IMMEDIATELY: "इनाम अगले दिन 00:00:00 बजे से दावा किए जा सकते हैं;",
        DELAY: "इनाम अगले दिन {time} बजे से दावा किए जा सकते हैं;",
      },
      BALANCE: {
        IMMEDIATELY: "इनाम अगले दिन 00:00:00 बजे से दावा किए जा सकते हैं;",
        DELAY: "इनाम अगले दिन {time} बजे से दावा किए जा सकते हैं;",
      },
    },
    WEEKLY: {
      ACTIVITY: {
        IMMEDIATELY: "इनाम अगले सोमवार 00:00:00 बजे से दावा किए जा सकते हैं;",
        DELAY: "इनाम अगले सोमवार {time} बजे से दावा किए जा सकते हैं;",
      },
      BALANCE: {
        IMMEDIATELY: "इनाम अगले सोमवार 00:00:00 बजे से दावा किए जा सकते हैं;",
        DELAY: "इनाम अगले सोमवार {time} बजे से दावा किए जा सकते हैं;",
      },
    },
  },
};

const assistanceTemplates_four: ActivityRulesMap_Recharge = {
  "zh-CN": {
    DAILY: {
      ABANDONED: `每个周期结束1天后，获得的奖励过期直接作废;`,
      AUTO: `每个周期结束1天后，获得的奖励过期自动派发到账;`
    },
    WEEKLY: {
      ABANDONED: `每个周期结束7天后，获得的奖励过期直接作废;`,
      AUTO: `每个周期结束7天后，获得的奖励过期自动派发到账;`
    },
  },
  "en-US": {
    DAILY: {
      ABANDONED: `The rewards obtained will expire and be invalidated directly after 1 day at the end of each cycle;`,
      AUTO: `The rewards obtained will expire and be automatically distributed to the account after 1 day at the end of each cycle;`
    },
    WEEKLY: {
      ABANDONED: `The rewards obtained will expire and be invalidated directly after 7 day at the end of each cycle;`,
      AUTO: `The rewards obtained will expire and be automatically distributed to the account after 7 day at the end of each cycle;`
    },
  },
  "pt-BR": {
    DAILY: {
      ABANDONED: `As recompensas recebidas expirarão 1 dias após o término de cada ciclo. As recompensas expiradas serão canceladas automaticamente;`,
      AUTO: `As recompensas recebidas expirarão 1 dias após o término de cada ciclo.As recebidas serão automaticamente distribuídas para a sua conta após a expiração;`
    },
    WEEKLY: {
      ABANDONED: `As recompensas recebidas expirarão 7 dias após o término de cada ciclo. As recompensas expiradas serão canceladas automaticamente;`,
      AUTO: `As recompensas recebidas expirarão 7 dias após o término de cada ciclo.As recebidas serão automaticamente distribuídas para a sua conta após a expiração;`
    },
  },
  "id-ID": {
    DAILY: {
      ABANDONED: `Setelah 1 hari berakhirnya setiap siklus, hadiah yang diperoleh akan hangus secara langsung;`,
      AUTO: `Setiap siklus berakhir 1 hari kemudian, hadiah yang diperoleh akan secara otomatis disalurkan ke akun;`
    },
    WEEKLY: {
      ABANDONED: `Setelah 7 hari berakhirnya setiap siklus, hadiah yang diperoleh akan hangus secara langsung;`,
      AUTO: `Setiap siklus berakhir 7 hari kemudian, hadiah yang diperoleh akan secara otomatis disalurkan ke akun;`
    },
  },
  "hi-IN": {
    DAILY: {
      ABANDONED: `प्रत्येक चक्र समाप्त होने के 1 दिन बाद, प्राप्त इनाम की अवधि समाप्त हो जाएगी और वह अमान्य हो जाएगा;`,
      AUTO: `प्रत्येक चक्र समाप्त होने के 1 दिन बाद, प्राप्त इनाम की अवधि समाप्त होने पर, वह स्वतः ही खाते में जमा हो जाएगा;`
    },
    WEEKLY: {
      ABANDONED: `प्रत्येक चक्र समाप्त होने के 7 दिन बाद, प्राप्त इनाम की अवधि समाप्त हो जाएगी और वह अमान्य हो जाएगा;`,
      AUTO: `प्रत्येक चक्र समाप्त होने के 7 दिन बाद, प्राप्त इनाम की अवधि समाप्त होने पर, वह स्वतः ही खाते में जमा हो जाएगा;`
    },
  },
};

// 会员答谢
const memberRewardTemplates: ActivityRulesMap = {
  "zh-CN": {
    1: `为答谢会员对平台的大力支持，特此推出“会员答谢”活动;`,
    2: `在活动期间内，会员充值/盈亏/有效投注，到达一定值，便可获得一定奖励，充值/盈亏/有效投注越高，奖励越丰富;`,
    3: `获得的奖励需要手动领取才可到账，若忘记领取，过期时奖励将自动发放到您的账户;`,
  },
  "en-US": {
    1: `To show our appreciation for the tremendous support from our members, we are launching the "Member Appreciation" event;`,
    2: `During the event period, members who reach a certain amount in deposits/losses/valid bets will receive a certain reward. The higher the deposits/losses/valid bets, the richer the rewards;`,
    3: `Rewards need to be claimed manually to be credited to your account. If you forget to claim them, the rewards will be automatically credited to your account upon expiration;`,
  },
  "pt-BR": {
    1: `Como agradecimento pelo grande apoio dos membros à plataforma, estamos lançando o evento "Apreciação aos Membros;`,
    2: `Durante o período do evento, os membros que atingirem um determinado valor em depósitos/perdas/apostas válidas receberão uma determinada recompensa. Quanto maiores os depósitos/perdas/apostas válidas, mais ricas serão as recompensas;`,
    3: `As recompensas precisam ser resgatadas manualmente para serem creditadas na sua conta. Se você esquecer de resgatá-las, as recompensas serão automaticamente creditadas na sua conta após o vencimento;`,
  },
  "id-ID": {
    1: `Sebagai apresiasi atas dukungan besar para anggota terhadap platform ini, kami meluncurkan acara "Apresiasi Anggota";`,
    2: `Selama periode acara, anggota yang mencapai jumlah tertentu dalam deposit/kerugian/taruhan yang valid akan menerima hadiah tertentu. Semakin tinggi deposit/kerugian/taruhan yang valid, semakin besar hadiahnya;`,
    3: `Hadiah yang diperoleh perlu diambil secara manual agar bisa masuk ke akun Anda. Jika lupa mengambilnya, hadiah akan otomatis dikreditkan ke akun Anda saat kadaluarsa;`,
  },
  "hi-IN": {
    1: `अपने सदस्यों के जबरदस्त समर्थन के प्रति अपनी प्रशंसा व्यक्त करने के लिए, हम "सदस्य प्रशंसा" कार्यक्रम शुरू कर रहे हैं;`,
    2: `कार्यक्रम की अवधि के दौरान, जो सदस्य जमा/हानि/वैध शर्तों में एक निश्चित राशि तक पहुँचते हैं, उन्हें एक निश्चित इनाम मिलेगा। जितनी अधिक जमा/हानि/वैध शर्तें होंगी, इनाम उतना ही अधिक होगा;`,
    3: `इनाम को आपके खाते में जमा करने के लिए मैन्युअल रूप से दावा करना होगा। यदि आप उन्हें दावा करना भूल जाते हैं, तो समाप्ति पर इनाम स्वचालित रूप से आपके खाते में जमा कर दिए जाएंगे;`,
  },
};

// 神秘彩金 第一条数据
const mysteryRewardTemplates_first: ActivityRulesMap_Rebate = {
  "zh-CN": {
    NEW_REGISTER: `新用户注册后自动报名参与活动，从报名当天开始，第2、3、7、15、30天可获得神秘奖品;`,
    ALL: `活动开启后，自动报名参与活动，从报名当天开始，第2、3、7、15、30天可获得神秘奖品;`,
  },
  "en-US": {
    NEW_REGISTER: `New users are automatically registered to participate in the activity upon registration. Starting from the day of registration, you can receive mystery prizes on the 2nd, 3rd, 7th, 15th, and 30th days;`,
    ALL: `Once the activity starts, you will be automatically registered to participate. Starting from the day of registration, you can receive mystery prizes on the 2nd, 3rd, 7th, 15th, and 30th days;`,
  },
  "pt-BR": {
    NEW_REGISTER: `Os novos usuários são automaticamente inscritos para participar da atividade após o registro. A partir do dia da inscrição, você pode receber prêmios misteriosos no 2º, 3º, 7º, 15º e 30º dias;`,
    ALL: `Após o início da atividade, você será automaticamente inscrito para participar. A partir do dia da inscrição, você poderá receber prêmios misteriosos no 2º, 3º, 7º, 15º e 30º dias;`,
  },
  "id-ID": {
    NEW_REGISTER: `Pengguna baru secara otomatis terdaftar untuk berpartisipasi dalam kegiatan setelah pendaftaran. Mulai dari hari pendaftaran, Anda dapat menerima hadiah misterius pada hari ke-2, ke-3, ke-7, ke-15, dan ke-30;`,
    ALL: `Setelah aktivitas dimulai, Anda akan secara otomatis terdaftar untuk berpartisipasi. Mulai dari hari pendaftaran, Anda dapat menerima hadiah misterius pada hari ke-2, ke-3, ke-7, ke-15, dan ke-30;`,
  },
  "hi-IN": {
    NEW_REGISTER: `नए उपयोगकर्ता पंजीकरण के बाद स्वचालित रूप से गतिविधि में शामिल हो जाते हैं। पंजीकरण के दिन से शुरू होकर, आपको 2, 3, 7, 15, और 30 दिनों पर रहस्यमय पुरस्कार मिल सकते हैं।;`,
    ALL: `गतिविधि शुरू होने के बाद, आपको स्वचालित रूप से भाग लेने के लिए पंजीकृत किया जाएगा। पंजीकरण के दिन से शुरू होकर, आप 2, 3, 7, 15 और 30 दिनों पर रहस्यमय पुरस्कार प्राप्त कर सकते हैं।;`,
  },
};

// 神秘彩金 第二条数据
const mysteryRewardTemplates_Two: ActivityRulesMap = {
  "zh-CN": {
    2: `充值越多，在线时间越长，活动奖励越大;`,
  },
  "en-US": {
    2: `The more you top up and the longer you stay online, the greater the activity rewards;`,
  },
  "pt-BR": {
    2: `Quanto mais você recarregar e quanto mais tempo você ficar online, maiores serão as recompensas da atividade;`,
  },
  "id-ID": {
    2: `Semakin banyak Anda melakukan pengisian dan semakin lama Anda online, semakin besar hadiah aktivitasnya;`,
  },
  "hi-IN": {
    2: `जितना अधिक आप रिचार्ज करेंगे और जितना अधिक आप ऑनलाइन रहेंगे, गतिविधि पुरस्कार उतना बड़ा होगा।;`,
  },
};

// 神秘彩金 第三条数据
const mysteryRewardTemplates_second = {
  "zh-CN": {
    NEW_REGISTER: {
      NONE: {
        ABANDONED: '活动于注册后第{awardKeepDays} 天23:59:59消失，未领取奖金视为放弃;',
        AUTO: '活动于注册后第{awardKeepDays} 天23:59:59消失，未领取奖金将自动派发;',
      },
      PERIODIC: {
        ABANDONED: '活动于注册后第{awardKeepDays} 天23:59:59重置，未领取奖金视为放弃;',
        AUTO: '活动于注册后第{awardKeepDays} 天23:59:59重置，未领取奖金将自动派发;',
      },
    },
    ALL: {
      NONE: {
        ABANDONED: '活动于报名后第{awardKeepDays} 天23:59:59消失，未领取奖金视为放弃;',
        AUTO: '活动于报名后第{awardKeepDays} 天23:59:59消失，未领取奖金将自动派发;',
      },
      PERIODIC: {
        ABANDONED: '活动于报名后第{awardKeepDays} 天23:59:59重置，未领取奖金视为放弃;',
        AUTO: '活动于报名后第{awardKeepDays} 天23:59:59重置，未领取奖金将自动派发;',
      },
    },
  },
  "en-US": {
    NEW_REGISTER: {
      NONE: {
        ABANDONED: 'The activity will expire at 23:59:59 on the {awardKeepDays} day after registration. Any unclaimed rewards will be considered forfeited;',
        AUTO: 'The activity will disappear at 23:59:59 on the {awardKeepDays} day after registration. Any unclaimed bonuses will be automatically distributed;',
      },
      PERIODIC: {
        ABANDONED: 'The event resets at 23:59:59 on the {awardKeepDays} day after registration. Bonuses not claimed will be considered forfeited;',
        AUTO: 'The event resets at 23:59:59 on the {awardKeepDays} day after registration. Any unclaimed bonuses will be automatically distributed;',
      },
    },
    ALL: {
      NONE: {
        ABANDONED: 'The activity will expire at 23:59:59 on the {awardKeepDays} day after registration. Any unclaimed rewards will be considered forfeited;',
        AUTO: 'The activity will expire at 23:59:59 on the {awardKeepDays} day after registration. Any unclaimed bonuses will be automatically distributed;',
      },
      PERIODIC: {
        ABANDONED: 'The event resets at 23:59:59 on the {awardKeepDays} day after registration. Unclaimed bonuses will be considered forfeited;',
        AUTO: 'The event resets at 23:59:59 on the {awardKeepDays} day after registration. Any unclaimed bonuses will be automatically distributed;',
      },
    },
  },
  "pt-BR": {
    NEW_REGISTER: {
      NONE: {
        ABANDONED: 'A atividade expirará às 23:59:59 do {awardKeepDays} dia após o registro. Recompensas não reivindicadas serão consideradas como perdidas;',
        AUTO: 'A atividade desaparecerá às 23:59:59 do {awardKeepDays}º dia após o registro. Os bônus não reclamados serão distribuídos automaticamente;',
      },
      PERIODIC: {
        ABANDONED: 'O evento é redefinido às 23:59:59 do {awardKeepDays}º dia após o registro. Bônus não resgatados serão considerados como desistidos;',
        AUTO: 'O evento é redefinido às 23:59:59 do {awardKeepDays}º dia após o registro. Bônus não resgatados serão automaticamente distribuídos;',
      },
    },
    ALL: {
      NONE: {
        ABANDONED: 'A atividade expirará às 23:59:59 do {awardKeepDays} dia após a inscrição. Recompensas não reivindicadas serão consideradas como perdidas;',
        AUTO: 'A atividade expirará às 23:59:59 no {awardKeepDays}º dia após a inscrição. Os bônus não reivindicados serão automaticamente distribuídos;',
      },
      PERIODIC: {
        ABANDONED: 'O evento é redefinido às 23:59:59 do {awardKeepDays}º dia após o registro. Bônus não resgatados serão considerados como desistência;',
        AUTO: 'O evento é redefinido às 23:59:59 do {awardKeepDays}º dia após a inscrição. Bônus não resgatados serão automaticamente distribuídos;',
      },
    },
  },
  "id-ID": {
    NEW_REGISTER: {
      NONE: {
        ABANDONED: 'Kegiatan akan berakhir pada pukul 23:59:59 pada hari ke-{awardKeepDays} setelah pendaftaran. Hadiah yang tidak diklaim akan dianggap hangus;',
        AUTO: 'Kegiatan akan menghilang pada pukul 23:59:59 pada hari ke-{awardKeepDays} setelah pendaftaran. Bonus yang belum diklaim akan didistribusikan secara otomatis;',
      },
      PERIODIC: {
        ABANDONED: 'Acara direset pada pukul 23:59:59 pada hari ke-{awardKeepDays} setelah pendaftaran. Bonus yang tidak diambil akan dianggap hangus;',
        AUTO: 'Acara direset pada pukul 23:59:59 pada hari ke-{awardKeepDays} setelah pendaftaran. Bonus yang tidak diambil akan secara otomatis didistribusikan;',
      },
    },
    ALL: {
      NONE: {
        ABANDONED: 'Kegiatan akan berakhir pada pukul 23:59:59 pada hari ke-{awardKeepDays} setelah pendaftaran. Hadiah yang tidak diklaim akan dianggap hangus;',
        AUTO: 'Kegiatan akan berakhir pada pukul 23:59:59 pada hari ke-{awardKeepDays} setelah pendaftaran. Bonus yang tidak diklaim akan secara otomatis didistribusikan;',
      },
      PERIODIC: {
        ABANDONED: 'Acara direset pada pukul 23:59:59 pada hari ke-{awardKeepDays} setelah pendaftaran. Bonus yang tidak diambil dianggap hangus;',
        AUTO: 'Acara direset pada pukul 23:59:59 pada hari ke-{awardKeepDays} setelah pendaftaran. Bonus yang tidak diambil akan secara otomatis didistribusikan;',
      },
    },
  },
  "hi-IN": {
    NEW_REGISTER: {
      NONE: {
        ABANDONED: 'गतिविधि पंजीकरण के {awardKeepDays} दिन के बाद 23:59:59 पर समाप्त हो जाएगी। अवार्ड जो प्राप्त नहीं किए गए हैं, उन्हें छोडा हुआ माना जाएगा।;',
        AUTO: 'पंजीकरण के {awardKeepDays} दिन बाद गतिविधि 23:59:59 पर समाप्त हो जाएगी। जो बोनस प्राप्त नहीं किए गए हैं, वे स्वचालित रूप से वितरित कर दिए जाएंगे।;',
      },
      PERIODIC: {
        ABANDONED: 'कार्यक्रम पंजीकरण के {awardKeepDays} दिन बाद 23:59:59 पर रीसेट हो जाएगा। जो बोनस प्राप्त नहीं किए गए हैं, उन्हें त्यागा हुआ माना जाएगा।;',
        AUTO: 'कार्यक्रम पंजीकरण के {awardKeepDays} दिन बाद 23:59:59 पर रीसेट होगा। जो बोनस प्राप्त नहीं किए गए हैं, वे स्वचालित रूप से वितरित कर दिए जाएंगे।;',
      },
    },
    ALL: {
      NONE: {
        ABANDONED: 'गतिविधि पंजीकरण के {awardKeepDays} दिन के बाद 23:59:59 पर समाप्त हो जाएगी। जो पुरस्कार प्राप्त नहीं किए गए हैं, उन्हें त्यागा हुआ माना जाएगा।;',
        AUTO: 'गतिविधि पंजीकरण के {awardKeepDays} दिन बाद 23:59:59 पर समाप्त होगी। जो बोनस प्राप्त नहीं किए गए हैं, उन्हें स्वचालित रूप से वितरित कर दिया जाएगा।;',
      },
      PERIODIC: {
        ABANDONED: 'कार्यक्रम पंजीकरण के {awardKeepDays} दिन के बाद 23:59:59 पर रीसेट हो जाएगा। जो बोनस प्राप्त नहीं किए गए हैं, उन्हें त्यागा हुआ माना जाएगा।;',
        AUTO: 'कार्यक्रम पंजीकरण के {awardKeepDays} दिन के बाद 23:59:59 पर रीसेट होगा। जो बोनस प्राप्त नहीं किए गए हैं, वे स्वचालित रूप से वितरित कर दिए जाएंगे।;',
      },
    },
  },
};

//全部平台
function generateFirstAgencyRule(language: Language, variables: ActivityVariables, ruleNumber: number): string {
  const agencyTemplate = agencyTemplates_first[language];
  if (!agencyTemplate) {
    return "Unsupported language";
  }

  const condition = variables?.validBetStatus || "OFF";
  const firstTemplate = agencyTemplate[condition];
  if (!firstTemplate) {
    return "Unsupported condition";
  }

  // 替换模板中的变量
  let finalTemplate = firstTemplate.replace("{highestReward}", `${formatAmount(Number(variables.highestReward), language)}`);
  if (condition === "ON") {
    if (variables?.limitAgencyType === 'ALL') {
      const platformTranslation = {
        "zh-CN": "全部平台",
        "en-US": "All Platforms",
        "pt-BR": "Todas as Plataformas",
        "id-ID": "Semua Platform",
        "hi-IN": "सभी प्लेटफॉर्म",
      };
      finalTemplate = finalTemplate.replace("{limitData}", platformTranslation[language]);
    } else {
      finalTemplate = finalTemplate.replace("{limitData}", `${variables?.limitAgencyData || ""}`);
    }
  }

  const ruleNumberValue = `${ruleNumber}. `;
  return ruleNumberValue + finalTemplate;
}

// 公用
const allActivityRules: { [activityType: string]: ActivityRulesMap } = {
  RedPacket: redPacketTemplates,
  Agency: agencyTemplates,
  AssistanceCash: assistanceCashTemplates,
  SignIn: signInTemplates,
  MemberReward: memberRewardTemplates,
};

// 生成默认规则
export function generateDefultRules(language: Language, activityType: string, variables: ActivityVariables) {
  let result = "";
  language = getLanguageCode(language);
  //活动内容
  const activityDetails = `${generateEventDetails(language)}\n`;

  if (activityType === "RedPacket" || activityType === "SignIn") {
    result += activityDetails + generateComRules(language as Language, activityType, variables);
    return result;
  }
  //带条件的活动
  if (activityType === "AssistanceCash") {
    result += activityDetails + generateComRules(language as Language, activityType, variables, 2);
  } else if (activityType === "Recharge") {
    result += activityDetails + generateRechargeRules(language as Language, variables);
  } else if (activityType === "Rebate") {
    result += activityDetails + generateRebateRules(language as Language, variables);
  } else if (activityType === "Agency") {
    result += activityDetails + generateFirstAgencyRule(language as Language, variables, 1) + "\n";
    result += generateComRules(language as Language, activityType, variables);
  } else if (activityType === "LuckyWheel") {
    result += activityDetails + generateLuckyWheelRules(language as Language, variables);
  } else if (activityType === "Assistance") {
    result += activityDetails + generateAssistanceRules(language as Language, variables);
  } else if (activityType === "MemberReward") {
    result += activityDetails + generateComRules(language as Language, activityType, variables);
  } else if (activityType === "MysteryReward") {
    result += activityDetails + generateMysteryRewardRules(language as Language, variables);
  }
  return result;
}

//  生成通用规则
export function generateComRules(language: Language, activityType: string, variables: ActivityVariables, isMore: number = 1): string {
  // 根据活动类型选择正确的规则集
  const activityRules = allActivityRules[activityType];
  if (!activityRules) {
    return "Unsupported activity type";
  }

  const templateEntries = activityRules[language];
  if (!templateEntries) {
    return "Unsupported language";
  }

  let result = "";
  Object.entries(templateEntries).forEach(([key, value], index, array) => {
    let template = value;
    Object.keys(variables).forEach(variableKey => {
      const regex = new RegExp(`{${variableKey}}`, 'g');
      const value = variableKey === 'highestReward' ? formatAmount(Number(variables[variableKey]), language) : variables[variableKey];
      template = template.replace(regex, value.toString());
    });
    result += `${key}. ${template}`;
    if (index < array.length - 1) {
      result += "\n";
    }
  });

  // 添加规则5和6
  const additionalRules = addFiveSixRules[language];
  if (additionalRules) {
    if (isMore == 1) {
      result += generateFourthRule(language, variables, 4);
      result += `\n5. ${additionalRules[5]}\n`;
      result += `6. ${additionalRules[6]}`;
    } else if (isMore == 2) {
      result += generateFourthRule(language, variables, 4);
      result += `\n5. ${additionalRules[5]}\n`;
      result += `6. ${additionalRules[6]}`;
    }
  }

  return result.trim();
}


// 特殊活动规则
export function specialRechargeRules(language: Language): string {
  const specialRules = {
    "zh-CN": "（奖金需在投注前领取，投注前未领取奖金立即作废）;",
    "en-US": " (the bonus must be claimed before betting, unclaimed bonuses before betting are immediately void);",
    "pt-BR": " (o bônus deve ser reivindicado antes de apostar, os bônus não reclamados antes de apostar são imediatamente anulados)；",
    "id-ID": " (Bonus harus diklaim sebelum taruhan ditempatkan, jika tidak diklaim sebelum taruhan, bonus akan langsung dibatalkan);",
    "hi-IN": " (बोनस को सट्टेबाजी से पहले प्राप्त करना होगा, सट्टेबाजी से पहले प्राप्त न किए गए बोनस तुरंत रद्द कर दिए जाएंगे);",
  };
  return specialRules[language];
}

// 神秘彩金活动
export function generateMysteryRewardRules(language: Language, variables: ActivityVariables): string {
  let result = "";

  // 第一条规则
  const firstTemplate = mysteryRewardTemplates_first[language][variables.joinType];
  if (firstTemplate) {
    result += `1. ${firstTemplate}\n`;
  }

  // 第二条规则
  const secondTemplate = mysteryRewardTemplates_Two[language];
  if (secondTemplate && secondTemplate[2]) {
    result += `2. ${secondTemplate[2]}\n`;
  }

  // 第三条规则 - 修改为使用 expiredAwardType
  const thirdTemplate = mysteryRewardTemplates_second[language][variables.joinType];
  if (thirdTemplate) {
    const resetTypeTemplate = thirdTemplate[variables.resetType];
    if (resetTypeTemplate) {
      const expiredTypeTemplate = resetTypeTemplate[variables.expiredAwardType];
      if (expiredTypeTemplate) {
        result += `3. ${expiredTypeTemplate.replace("{awardKeepDays}", variables.awardKeepDays.toString())}\n`;
      }
    }
  }

  // 添加第四条规则（使用公共模板）
  result += generateFourthRule(language, variables, 4);

  // 添加第五和第六条规则（使用公共模板）
  const additionalRules = addFiveSixRules[language];
  if (additionalRules) {
    result += `\n5. ${additionalRules[5]}\n`;
    result += `6. ${additionalRules[6]}`;
  }

  return result.trim();
}

// 充值活动
export function generateRechargeRules(language: Language, variables: ActivityVariables): string {
  // 首先，根据语言和充值类型选择正确的模板
  const template = rechargeTemplates_First[language][variables.rechargeType][variables.resetType];
  if (!template) {
    return "Unsupported recharge type or reset type";
  }

  // 替换模板中的变量
  let result = "1. " + template.replace("{highestReward}", `${formatAmount(Number(variables.highestReward), language)}`);

  if (variables.awardType === "ACTIVITY" && variables.rewardAuditType === "GiftAndRecharge" && variables.rechargeType !== "SUM") {
    if (result.endsWith(";")) {
      result = result.slice(0, -1);
    }
    result += specialRechargeRules(language);
  }

  // 充值活动的2条数据
  const otherTemplates = rechargeTemplates_Two[language];
  if (!otherTemplates) {
    return "Unsupported language";
  }
  // 直接添加充值活动的2条数据
  Object.entries(otherTemplates).forEach(([key, value]) => {
    result += `\n${key}. ${value}`;
  });

  // 充值活动的3条数据
  const thirdTemplate = rechargeTemplates_Three[language][variables.awardType];
  if (!thirdTemplate) {
    return "Unsupported recharge type";
  }
  result += `\n3. ${thirdTemplate}`;

  result += generateGiftRule(language, variables, 4);

  // 添加规则5和6
  const rulesFiveSix = addFiveSixRules[language];
  if (rulesFiveSix) {
    result += `\n5. ${rulesFiveSix[5]}\n6. ${rulesFiveSix[6]}`;
  }

  return result;
}

function generateFourthRule(language: Language, variables: ActivityVariables, ruleNumber: number): string {
  const comTemplate = comTemplates_four[language];
  if (!comTemplate) {
    return "Unsupported language";
  }

  const condition = variables.limitType;
  const fourthTemplate = comTemplate[condition];
  if (!fourthTemplate) {
    return "Unsupported condition";
  }

  // 替换模板中的变量
  let finalTemplate = fourthTemplate.replace("{multiplier}", `${variables.multiplier}`);
  if (condition === "ON") {
    // 如果条件是ON，还需要替换{limitData}
    // ON: `本活动所赠送的奖金（不含本金）需{multiplier}倍有效投注才能提现，{投注仅限limitData};`,
    finalTemplate = finalTemplate.replace("{limitData}", `${variables.limitData}`);
  }

  const ruleNumberValue = `\n${ruleNumber}. `;
  return ruleNumberValue + finalTemplate;
}


function generateGiftRule(language: Language, variables: ActivityVariables, ruleNumber: number): string {
  const comTemplateGift = comTemplates_four_Gift[language];
  if (!comTemplateGift) {
    return "Unsupported language";
  }

  const giftTemplate = comTemplateGift[variables.limitType];
  if (!giftTemplate) {
    return "Unsupported condition";
  }

  // 根据奖金类型选择模板
  const auditType = variables.rewardAuditType; // 假设 variables 中包含奖金类型（Gift 或 GiftAndRecharge）
  const selectedTemplate = giftTemplate[auditType];
  if (!selectedTemplate) {
    return "Unsupported bonus type";
  }

  // 替换模板中的变量
  let finalTemplate = selectedTemplate.replace("{multiplier}", `${variables.multiplier}`);
  if (variables.limitType === "ON") {
    // 如果条件是ON，还需要替换{limitData}
    finalTemplate = finalTemplate.replace("{limitData}", `${variables.limitData}`);
  } else {
    // 如果条件是OFF，替换文本以适应“不限游戏平台”的情况
    // 注意：这里假设OFF条件下不需要特别替换，因为模板已经是通用的描述
  }

  const ruleNumberValue = `\n${ruleNumber}. `;
  return ruleNumberValue + finalTemplate;
}
// 返水活动
export function generateRebateRules(language: Language, variables: ActivityVariables): string {
  // 选择返水活动的第一条数据模板
  const firstTemplate = rebateTemplates_First[language];
  if (!firstTemplate) {
    return "Unsupported language";
  }

  // 根据充值类型（RECHARGE 或 NORECHARGE）选择正确的模板
  const rebateTypeTemplate = firstTemplate[variables.rebateType as keyof typeof firstTemplate];
  if (!rebateTypeTemplate) {
    return "Unsupported recharge type";
  }

  // 替换模板中的变量
  let result = "1. " + rebateTypeTemplate
    .replace("{lowestRebateBet}", `${formatAmount(Number(variables.lowestRebateBet), language)}`)
    .replace("{rechargeLimit}", `${formatAmount(Number(variables.rechargeLimit), language)}`)
    .replace("{highestReward}", `${formatAmount(Number(variables.highestReward), language)}`);

  // 对于返水活动的2, 3条数据，我们需要额外处理
  const otherTemplates = rebateTemplates_Other[language];
  if (!otherTemplates) {
    return "Unsupported language";
  }

  // 添加返水活动的2, 3条数据
  Object.entries(otherTemplates).forEach(([key, value]) => {
    result += `\n${key}. ${value}`;
  });

  result += generateFourthRule(language, variables, 4);

  // 添加规则5和6
  const rulesFiveSix = addFiveSixRules[language];
  if (rulesFiveSix) {
    result += `\n5. ${rulesFiveSix[5]}\n6. ${rulesFiveSix[6]}`;
  }

  return result.trim();
}

// 幸运转盘
export function generateLuckyWheelRules(language: Language, variables: ActivityVariables): string {
  // 合并第一和第二条规则
  const firstSecondTemplate = luckyWheelTemplates_firstSecond[language];
  let result = Object.entries(firstSecondTemplate).map(([key, value]) => {
    return `${key}. ${value.replace("{exchangeReward}", formatAmount(Number(variables.exchangeReward), language))}`;
  }).join("\n");

  // 添加第三条规则，根据重置类型选择
  const thirdTemplate = luckyWheelTemplates_third[language][variables.resetType];
  result += `\n3. ${thirdTemplate}`;

  // 添加第四条规则
  result += generateFourthRule(language, variables, 4);

  // 添加公共的第5和第6条规则
  const additionalRules = addFiveSixRules[language];
  if (additionalRules) {
    result += `\n5. ${additionalRules[5]}\n6. ${additionalRules[6]}`;
  }

  return result;
}

// 救援金 第三条规则
function getRewardDistributionMessage(language: Language, frequency: ResetType, type: AwardType, delay: AwardTimeType, time?: string): string {
  // 检查frequency是否为 中的有效键
  if (!['DAILY', 'WEEKLY'].includes(frequency)) {
    throw new Error(`Unsupported frequency: ${frequency}`);
  }

  const messageTemplate = rewardDistributionMapWithDelay[language][frequency][type][delay];

  // 如果是延迟到账，替换模板中的{time}占位符
  if (delay === 'DELAY' && time) {
    return messageTemplate.replace('{time}', time);
  }

  return messageTemplate;
}
// 救援金规则生成，包括第六条使用幸运转盘的规则，以及第七、八条使用公共规则
export function generateAssistanceRules(language: Language, variables: ActivityVariables): string {
  // 救援金第一条数据
  const firstTemplate = assistanceTemplates_first[language];
  if (!firstTemplate) {
    return "Unsupported language";
  }

  // 根据 resetType 和 rewardType 选择模板
  const resetTypeTemplate = firstTemplate[variables.resetType as keyof typeof firstTemplate];
  if (!resetTypeTemplate) {
    return "Unsupported reset type";
  }

  const rewardTypeTemplate = resetTypeTemplate[variables.rewardType as keyof typeof resetTypeTemplate];
  if (!rewardTypeTemplate) {
    return "Unsupported reward type";
  }

  // 替换模板中的变量
  let result = "1. " + rewardTypeTemplate
    .replace("{lowestLoss}", `${formatAmount(Number(variables.lowestLoss), language)}`)
    .replace("{highestReward}", `${formatAmount(Number(variables.highestReward), language)}`);

  // 救援金第二条数据   固定 / 比例  excludeGift 有优惠 / normal 无优惠
  const secondTemplate = assistanceTemplates_second[language][variables.rewardType][variables.includeOrExclude];
  if (secondTemplate) {
    result += `\n2. ${secondTemplate}`;
  }

  const message = getRewardDistributionMessage(language, variables.resetType as ResetType, variables.awardType as AwardType, variables.awardTimeType as AwardTimeType, variables.time as string);
  result += `\n3. ${message}`;

  // 救援金第四条数据 abandoned 过期作废 / auto 过期自动派发
  const fifthTemplate = assistanceTemplates_four[language][variables.resetType as ResetType][variables.expiredAwardType as ExpiredAwardType];
  if (fifthTemplate) {
    result += `\n4. ${fifthTemplate}`;
  }

  result += generateFourthRule(language, variables, 5);

  // 第七、八条数据，使用公共规则模板 c
  const commonRulesTemplate = addFiveSixRules[language];
  if (commonRulesTemplate) {
    result += `\n6. ${commonRulesTemplate[5]}`;
    result += `\n7. ${commonRulesTemplate[6]}`;
  }

  return result.trim();
}

// 活动内容
export function generateEventDetails(language: Language): string {
  const eventDetails = {
    "zh-CN": "活动内容: ",
    "en-US": "Event Details: ",
    "pt-BR": "Detalhes do Evento: ",
    "id-ID": "Detail Acara: ",
    "hi-IN": "गतिविधि सामग्री: ",
  };
  return eventDetails[language];
}


const previewText_First: ActivityRulesMap = {
  "zh-CN": {
    OTHER: "最高奖励{maximum}",
    REBATE: "最高返水比例{maximum}%",
    OTHER_RANGE: "最高奖励{maximum}%",
  },
  "en-US": {
    OTHER: "Maximum bonus {maximum}",
    REBATE: "Maximum rebate rate {maximum}%",
    OTHER_RANGE: "Maximum bonus {maximum}%",
  },
  "pt-BR": {
    OTHER: "Bônus máximo {maximum}",
    REBATE: "Taxa máxima de cashback {maximum}%",
    OTHER_RANGE: "Bônus máximo {maximum}%",
  },
  "id-ID": {
    OTHER: "Hadiah Tertinggi {maximum}",
    REBATE: "Persentase Pengembalian Uang Tertinggi {maximum}%",
    OTHER_RANGE: "Hadiah Tertinggi {maximum}%",
  },
  "hi-IN": {
    OTHER: "अधिकतम बोनस {maximum}",
    REBATE: "अधिकतम रिबेट दर {maximum}%",
  },
};

const previewText_Second: ActivityRulesMap = {
  "zh-CN": {
    NONE: "不重置",
    DAILY: "每日重置",
    WEEKLY: "每周重置",
    WEEKLY_DAY: "每周重置",
    MONTHLY_DAY: "每月重置",
    PERIODIC: "跟随活动",
  },
  "en-US": {
    NONE: "No reset",
    DAILY: "Reset daily",
    WEEKLY: "Reset weekly",
    WEEKLY_DAY: "Reset weekly",
    MONTHLY_DAY: "Reset monthly",
    PERIODIC: "Following activity",
  },
  "pt-BR": {
    NONE: "Sem reinício",
    DAILY: "Reinício diário",
    WEEKLY: "Reinício semanal",
    WEEKLY_DAY: "Reinício semanal",
    MONTHLY_DAY: "Reinício mensal",
    PERIODIC: "Atividade seguinte",
  },
  "id-ID": {
    NONE: "Tidak Mereset",
    DAILY: "Reset Harian",
    WEEKLY: "Reset Mingguan",
    WEEKLY_DAY: "Reset Mingguan",
    MONTHLY_DAY: "Reset Bulanan",
    PERIODIC: "Kegiatan lanjutan",
  },
  "hi-IN": {
    NONE: "रीसेट नहीं",
    DAILY: "दैनिक रीसेट",
    WEEKLY: "साप्ताहिक रीसेट",
    WEEKLY_DAY: "साप्ताहिक रीसेट",
    MONTHLY_DAY: "हर महीने रीसेट",
    PERIODIC: "अनुवर्ती गतिविधि",
  },
};

const previewText_Second_mysteryReward: ActivityRulesMap = {
  "zh-CN": {
    NONE: "一次性",
    PERIODIC: "循环",
  },
  "en-US": {
    NONE: "Disposable",
    PERIODIC: "Cycle",
  },
  "pt-BR": {
    NONE: "Descartável",
    PERIODIC: "Ciclo",
  },
  "id-ID": {
    NONE: "Disposable",
    PERIODIC: "Siklus",
  },
  "hi-IN": {
    NONE: "डिस्पोजेबल",
    PERIODIC: "चक्र",
  },
};

const previewText_Third: ActivityRulesMap = {
  "zh-CN": {
    ABANDONED: "过期作废",
    AUTO: "过期自动发放",
    RESERVE: "跨天，跨周，跨月时，未领取奖励邮件下发",
    ABOLISHMENT: "跨天，跨周，跨月时，未领取奖励系统回收作废",
  },
  "en-US": {
    ABANDONED: "Void if not claimed",
    AUTO: "Auto-payout upon expiration",
    RESERVE: "跨天，跨周，跨月时，未领取奖励邮件下发",
    ABOLISHMENT: "跨天，跨周，跨月时，未领取奖励系统回收作废",
  },
  "pt-BR": {
    ABANDONED: "Nulo se não reclamado",
    AUTO: "Pagamento automático ao expirar",
    RESERVE: "跨天，跨周，跨月时，未领取奖励邮件下发",
    ABOLISHMENT: "跨天，跨周，跨月时，未领取奖励系统回收作废",
  },
  "id-ID": {
    ABANDONED: "Kadaluarsa dan Dinyatakan Tidak Berlaku",
    AUTO: "Otomatis Dikeluarkan saat Kadaluarsa",
    RESERVE: "跨天，跨周，跨月时，未领取奖励邮件下发",
    ABOLISHMENT: "跨天，跨周，跨月时，未领取奖励系统回收作废",
  },
  "hi-IN": {
    ABANDONED: "यदि दावा नहीं किया गया तो अमान्य",
    AUTO: "समाप्ति पर स्वत: भुगतान",
    RESERVE: "跨天，跨周，跨月时，未领取奖励邮件下发",
    ABOLISHMENT: "跨天，跨周，跨月时，未领取奖励系统回收作废",
  },
};

// 定义充值活动名字
export function getRechargeActivityName(language: Language, resetType: ResetType, rechargeType: RechargeType): string {
  const names = {
    "zh-CN": {
      NONE: {
        FIRST: "首充额外奖励",
        SINGLE: "单笔充值加赠",
        SUM: "累计充值加赠",
      },
      DAILY: {
        FIRST: "每日首充加赠",
        SINGLE: "每日单笔充值加赠",
        SUM: "每日累计充值加赠",
      },
      WEEKLY: {
        FIRST: "每周首充加赠",
        SINGLE: "每周单笔充值加赠",
        SUM: "每周累计充值加赠",
      },
    },
    "en-US": {
      NONE: {
        FIRST: "First Deposit Extra Reward",
        SINGLE: "Additional bonus for every recharge",
        SUM: "Cumulative recharge additional reward",
      },
      DAILY: {
        FIRST: "Daily First Deposit Extra Reward",
        SINGLE: "Daily Additional bonus for every recharge",
        SUM: "Daily Cumulative recharge additional reward",
      },
      WEEKLY: {
        FIRST: "Weekly First Deposit Extra Reward",
        SINGLE: "Weekly Additional bonus for every recharge",
        SUM: "Weekly Cumulative recharge additional reward",
      },
    },
    "pt-BR": {
      NONE: {
        FIRST: "Recompensa Extra para Primeiro Depósito",
        SINGLE: "Bônus adicional para cada recarga",
        SUM: "Recompensa adicional de recarga acumulativa",
      },
      DAILY: {
        FIRST: "Recompensa Extra de Primeiro Depósito Diário",
        SINGLE: "Bônus adicional diário para cada recarga",
        SUM: "Recompensa adicional diária por recarga acumulada",
      },
      WEEKLY: {
        FIRST: "Recompensa Extra de Primeiro Depósito Semanal",
        SINGLE: "Bônus adicional semanal para cada recarga",
        SUM: "Bônus Semanal de Recarga Cumulativa",
      },
    },
    "id-ID": {
      NONE: {
        FIRST: "Bonus tambahan untuk isi ulang pertama",
        SINGLE: "Bonus tambahan untuk setiap isi ulang",
        SUM: "Bonus tambahan untuk akumulasi isi ulang",
      },
      DAILY: {
        FIRST: "Bonus tambahan untuk isi ulang pertama setiap hari",
        SINGLE: "Bonus tambahan untuk setiap isi ulang tunggal harian",
        SUM: "Bonus tambahan untuk akumulasi isi ulang harian",
      },
      WEEKLY: {
        FIRST: "Bonus tambahan untuk isi ulang pertama setiap minggu",
        SINGLE: "Bonus tambahan untuk setiap isi ulang tunggal mingguan",
        SUM: "Bonus tambahan untuk akumulasi isi ulang mingguan",
      },
    },
    "hi-IN": {
      NONE: {
        FIRST: "पहली जमा पर अतिरिक्त इनाम",
        SINGLE: "प्रत्येक रिचार्ज पर अतिरिक्त बोनस",
        SUM: "संचयी रिचार्ज अतिरिक्त इनाम",
      },
      DAILY: {
        FIRST: "दैनिक पहले जमा पर अतिरिक्त इनाम",
        SINGLE: "प्रत्येक रिचार्ज पर दैनिक अतिरिक्त बोनस",
        SUM: "दैनिक संचयी रिचार्ज अतिरिक्त इनाम",
      },
      WEEKLY: {
        FIRST: "साप्ताहिक पहले जमा पर अतिरिक्त इनाम",
        SINGLE: "प्रत्येक रिचार्ज पर साप्ताहिक अतिरिक्त बोनस",
        SUM: "साप्ताहिक संचयी रिचार्ज अतिरिक्त इनाम",
      },
    },
  };

  return names[language][resetType][rechargeType];
}

// 获取救援金活动名字
function getRescueFundActivityName(language: Language, resetType: ResetType): string {
  const names = {
    "zh-CN": {
      NONE: "",
      DAILY: "每日救援金",
      WEEKLY: "每周救援金",
    },
    "en-US": {
      NONE: "",
      DAILY: "Daily loss rescue fund",
      WEEKLY: "Weekly loss rescue fund",
    },
    "pt-BR": {
      NONE: "",
      DAILY: "Fundo de Resgate de Perdas Diárias",
      WEEKLY: "Fundo de Resgate de Perdas Semanais",
    },
    "id-ID": {
      NONE: "",
      DAILY: "Uang bantuan harian",
      WEEKLY: "Uang bantuan mingguan",
    },
    "hi-IN": {
      NONE: "",
      DAILY: "दैनिक राहत कोष",
      WEEKLY: "साप्ताहिक राहत कोष",
    },
  };

  return names[language][resetType];
}

// 幸运转盘活动名字
function getLuckyWheelActivityName(language: Language, resetType: ResetType): string {
  const names = {
    "zh-CN": {
      NONE: "幸运转盘",
      DAILY: "每日幸运转盘",
      WEEKLY: "每周幸运转盘",
    },
    "en-US": {
      NONE: "Lucky Spin",
      DAILY: "Daily Lucky Spin",
      WEEKLY: "Weekly Lucky Spin",
    },
    "pt-BR": {
      NONE: "Giro da Sorte",
      DAILY: "Giro da Sorte Diário",
      WEEKLY: "Giro da Sorte Semanal",
    },
    "id-ID": {
      NONE: "Roda Keberuntungan",
      DAILY: "Roda Keberuntungan Harian",
      WEEKLY: "Roda Keberuntungan Mingguan",
    },
    "hi-IN": {
      NONE: "लकी रूले",
      DAILY: "दैनिक लकी रूले",
      WEEKLY: "साप्ताहिक लकी रूले",
    },
  };

  return names[language][resetType];
}

// 生成活动名字
export function getActivityDefaultName(language: Language, activityType: AcviticyType, variables: ActivityVariables): string {
  language = getLanguageCode(language);
  if (activityType === 'Recharge') {
    return getRechargeActivityName(language, variables.resetType as ResetType, variables.rechargeType as RechargeType)
  } else if (activityType === 'Assistance') {
    return getRescueFundActivityName(language, variables.resetType as ResetType)
  } else if (activityType === 'LuckyWheel') {
    return getLuckyWheelActivityName(language, variables.resetType as ResetType)
  }

  const activityNames = {
    Recharge: {},
    Assistance: {},
    LuckyWheel: {},
    SignIn: {
      "zh-CN": "签到",
      "en-US": "Sign in",
      "pt-BR": "Entrar",
      "id-ID": "Pendaftaran",
      "hi-IN": "साइन इन",
    },
    Custom: {
      "zh-CN": "自定义",
      "en-US": "Custom event",
      "pt-BR": "Evento personalizado",
      "id-ID": "Disesuaikan",
      "hi-IN": "कस्टम इवेंट",
    },
    RechargeReward: {
      "zh-CN": "充值赠送",
      "en-US": "Recharge discount",
      "pt-BR": "Desconto de recarga",
      "id-ID": "Bonus Isi Ulang",
      "hi-IN": "रिचार्ज छूट",
    },
    RedPacket: {
      "zh-CN": "神秘矿场",
      "en-US": "Mysterious Mine",
      "pt-BR": "Mina Misteriosa",
      "id-ID": "Tambang Misterius",
      "hi-IN": "रहस्यमय खदान",
    },
    Agency: {
      "zh-CN": "推荐好友领彩金",
      "en-US": "Refer friends to receive a bonus",
      "pt-BR": "Indique amigos para receber um bônus",
      "id-ID": "Rekomendasikan teman untuk menerima bonus",
      "hi-IN": "दोस्तों को रेफर करें और बोनस प्राप्त करें।",
    },
    Rebate: {
      "zh-CN": "返水活动",
      "en-US": "Wagering Cashback",
      "pt-BR": "Cashback de Apostas",
      "id-ID": "Program Pengembalian Uang",
      "hi-IN": "शर्त लगाने पर कैशबैक",
    },
    AssistanceCash: {
      "zh-CN": "助力领现金",
      "en-US": "Earn cash by inviting friends",
      "pt-BR": "Ganhe dinheiro convidando amigos",
      "id-ID": "Bantu untuk Dapatkan Uang Tunai",
      "hi-IN": "दोस्तों को आमंत्रित करके नकद कमाएं",
    },
    MemberReward: {
      "zh-CN": "会员答谢",
      "en-US": "Member Appreciation",
      "pt-BR": "Apreciação aos Membros",
      "id-ID": "Apresiasi Anggota",
      "hi-IN": "सदस्य प्रशंसा",
    },
    MysteryReward: {
      "zh-CN": "神秘彩金活动",
      "en-US": "Mystery Bonus Event",
      "pt-BR": "Evento de Bônus Misterioso",
      "id-ID": "Acara Bonus Misteri",
      "hi-IN": "रहस्यमय बोनस इवेंट",
    },
  };

  return activityNames[activityType][language] || "Unknown Activity";
}

// 获取当前活动名字
export function getCurrentActivityName(multilingual: Record<string, string>, language: Language, activityType: AcviticyType,) {
  if (multilingual.nameType === "CUSTOM") return multilingual.name
  const params = JSON.parse(multilingual?.nameParams)
  return getActivityDefaultName(language, activityType, params.variablesValue)
}

// 根据当前语言格式化金额
export function formatAmount(amount: number, language: Language): string {
  language = getLanguageCode(language);
  return new Intl.NumberFormat(language, { style: 'decimal' }).format(amount);
}

// 生成预览文本
export function generatePreviewText(language: Language, variables: ActivityVariables): string {
  language = getLanguageCode(language);
  // 根据语言和类型选择正确的模板
  const firstTemplate = previewText_First[language][variables.highestType];
  if (!firstTemplate) {
    return "Unsupported type";
  }

  // 替换模板中的变量
  let result = `1. ${firstTemplate.replace("{maximum}", formatAmount(Number(variables.highestReward), language))}`;

  // 设置默认的 activityType 为 Recharge
  const activityType = variables?.activityType || 'Recharge';

  // 添加第二条数据
  const secondTemplate = activityType === 'MysteryReward'
    ? previewText_Second_mysteryReward[language][variables.resetType]
    : previewText_Second[language][variables.resetType];

  if (secondTemplate) {
    result += `\n2. ${secondTemplate}`;
  }

  // 添加第三条数据
  const thirdTemplate = previewText_Third[language][variables.expiredAwardType];
  if (thirdTemplate) {
    result += `\n3. ${thirdTemplate}`;
  }

  return result;
}

export function getLanguageCode(language: Language): Language {
  if (language === 'en-PH') {
    return 'en-US';
  }
  return language; // 返回原语言
}
