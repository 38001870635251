import useCopyAccountInfo from '@/hooks/useCopyAccountInfo'
import { delay } from '@/utils/delay'
import { urlCheck } from '@/hooks/UrlCheck';
import { emitter } from "@/utils/event";
export default async () => {
  const channelStore = useChannelStore();
  const apkUrl = channelStore.promotionInfo?.installUrl;
  const reg = /.+_(\d+)\.apk$/
  const id = apkUrl?.match(reg)?.[1]
  useCopyAccountInfo()
  channelStore.setInstallStatus('Installing')
  emitter.emit('pwa/updateProgress', {})
  location.href = `intent://open#Intent;scheme=panda_${id};end`
  await delay(3000)
  location.href = urlCheck(apkUrl);

}
